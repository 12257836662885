var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DatePickerMode } from "../../core/constants";
import store from "../../core/store";
import { DateHelper } from "../../core/helpers/dateHelper";
/***
 * Emits  datePickerChanged
 */
var DatePicker = /** @class */ (function (_super) {
    __extends(DatePicker, _super);
    function DatePicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.date = null;
        return _this;
    }
    DatePicker.prototype.mounted = function () {
        this.date = new Date();
    };
    DatePicker.prototype.getFormat = function () {
        var format = store.state.settings.DateFormat;
        var flatPickerFormat = {};
        flatPickerFormat.dateFormat = "M/d/Y h:i K";
        flatPickerFormat.enableTime =
            this.mode == DatePickerMode.Time || this.mode === DatePickerMode.DateTime;
        flatPickerFormat.noCalendar = this.mode == DatePickerMode.Time;
        flatPickerFormat.defaultHour = this.defaultHour;
        flatPickerFormat.defaultMinute = this.defaultMinute;
        flatPickerFormat.time_24hr = format.includes("tt") ? false : true;
        flatPickerFormat.dateFormat = this.toFlatPickrFormat();
        return flatPickerFormat;
    };
    DatePicker.prototype.toFlatPickrFormat = function () {
        var format = store.state.settings.DateFormat;
        format = format.replace(" tt", ""); // Remove AM-PM info as it is set in time_24h parameter
        var formatParts = format.toString().split(" ");
        //Date part - Remove duplicates, leave year as Y, month as M. Example dd/MM/yyyy -> d/m/Y
        var dateFormat = formatParts[0]
            .replace(/y/g, "Y")
            .replace(/M/g, "m")
            .replace(/(.)\1+/g, "$1");
        if (this.mode == DatePickerMode.Date) {
            return dateFormat;
        }
        //Hour part - Remove duplicates, leave minute as i
        var hourFormat = formatParts[1].replace(/m/g, "i").replace(/(.)\1+/g, "$1");
        if (formatParts.length > 2) {
            //If K parameter is defined
            hourFormat += " " + formatParts[2];
        }
        if (this.mode == DatePickerMode.Time) {
            return hourFormat;
        }
        return dateFormat + " " + hourFormat;
    };
    DatePicker.prototype.onChange = function (selectedDates) {
        var refsDate = this.$refs.date;
        return {
            field: this.field,
            strDate: refsDate.$el.value,
            date: selectedDates[0],
            systemStrDate: DateHelper.toSystemString(selectedDates[0]),
        };
    };
    __decorate([
        Prop({ default: "" })
    ], DatePicker.prototype, "label", void 0);
    __decorate([
        Prop({ default: DatePickerMode.Date })
    ], DatePicker.prototype, "mode", void 0);
    __decorate([
        Prop({ required: false })
    ], DatePicker.prototype, "field", void 0);
    __decorate([
        Prop({ required: false })
    ], DatePicker.prototype, "required", void 0);
    __decorate([
        Prop({ default: "form-control defaultPicker" })
    ], DatePicker.prototype, "flatPickrClass", void 0);
    __decorate([
        Prop({ default: false })
    ], DatePicker.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: 8 })
    ], DatePicker.prototype, "defaultHour", void 0);
    __decorate([
        Prop({ default: 0 })
    ], DatePicker.prototype, "defaultMinute", void 0);
    __decorate([
        Prop({ required: false })
    ], DatePicker.prototype, "flatPickrId", void 0);
    __decorate([
        Emit("datePickerChanged")
    ], DatePicker.prototype, "onChange", null);
    DatePicker = __decorate([
        Component({
            components: { flatPickr: flatPickr },
        })
    ], DatePicker);
    return DatePicker;
}(Vue));
export default DatePicker;
