












import {
  Component,
  Vue,
} from "../../shared/node_modules/vue-property-decorator";
import SecureLayout from "./layouts/secure.vue";
import InSecureLayout from "./layouts/insecure.vue";
import { Core } from "./js/core.min.js";
import { RdNavBar } from "./js/rdnavbar.js";
import { Popper } from "./js/popper.js";
import { JQueryEventMove } from "./js/jqueryeventmove.js";
import { JQueryResize } from "./js/jqueryresize.js";
import { PointerEvents } from "./js/pointer-events.min.js";
import GlobalError from "../../shared/components/globalError/globalError.vue";
//import "./js/script.js";
import store from "../../shared/core/store";

@Component({
  components: {
    SecureLayout,
    InSecureLayout,
    GlobalError,
  },
})
export default class App extends Vue {
  layout: string = "";
  reload: number = 0;
  created() {
    this.layout = this.$route.meta?.layout || "insecure";
    this.setDefaultReloadMessage();
  }

  async mounted() {
    // await store.dispatch("loadSettings").then((result) => {
    //   //this.reload++;
    // });
  }

  setDefaultReloadMessage() {
    localStorage.setItem(window.location.host + "_cz_new_version_reload_message", this.$t("The system has been updated").toString());
  }
}
