import { EditPathMath } from "./EditPathMath";
import { Calcs } from "./Calcs";
var DrawPaths = /** @class */ (function () {
    function DrawPaths() {
        this.fontSize = 20;
        this.scale = 1.85;
        this.editPathMath = new EditPathMath();
        this.cals = new Calcs();
    }
    DrawPaths.prototype.getGroupsPath = function (pathss, type) {
        var _this = this;
        var draws = [];
        var paths = pathss.paths;
        if (type === undefined) {
            type = "data";
        }
        var data = pathss[type];
        data.forEach(function (item) {
            var path = paths[item.ref];
            var draw = _this.addPath(item, path, draws.length, type);
            draws.push(draw);
        });
        return draws;
    };
    DrawPaths.prototype.addPathToGroup = function (draws, item, path, type) {
        var ind = draws.length;
        var draw = this.addPath(item, path, ind, type);
        draws.push(draw);
        return draws;
    };
    DrawPaths.prototype.addPath = function (item, path, ind, type) {
        var def = path.d;
        var w1 = "";
        var h1 = "";
        var x1 = "";
        var y1 = "";
        var trans = " translate(" +
            item.trans[0] +
            "," +
            item.trans[1] +
            ") rotate(" +
            item.rot +
            ") ";
        if (path.type === "rect") {
            var dd = def.split(" ");
            w1 = dd[0];
            h1 = dd[1];
            def = "";
            x1 = -(w1 / 2);
            y1 = -(h1 / 2);
        }
        var textTrans = "";
        var section = -1;
        if (item.section !== undefined) {
            section = item.section;
        }
        var sectionBlocked = -1;
        if (item.section !== undefined) {
            sectionBlocked = item.section;
        }
        var draw = {
            type: path.type,
            label: item.label,
            x: x1,
            y: y1,
            width: w1,
            height: h1,
            def: def,
            transform: trans,
            x0: item.trans[0],
            y0: item.trans[1],
            isActive: false,
            isDragon: false,
            rot: item.rot,
            textTrans: textTrans,
            section: section,
            sectionBlocked: sectionBlocked,
            ind: ind,
            op: type,
            id: path.id,
            placeId: item.id,
            row: item.row,
            col: item.col
        };
        if (path.type === "path") {
            // textTrans = this.textCenter(path.d, draw);
            var aa = this.getCentroid1(draw);
            var defv = def.split(" ");
            defv[1] = Number(defv[1]) - aa[0];
            defv[2] = Number(defv[2]) - aa[1];
            def = defv.join(" ");
            draw.def = def;
            var bb = this.getCentroid1(draw);
            w1 = bb[0];
            h1 = bb[1] + this.fontSize / 4;
            textTrans = " translate(" + w1 + "," + h1 + ") ";
            draw.textTrans = textTrans;
        }
        return draw;
    };
    DrawPaths.prototype.getCentroid1 = function (path) {
        var rx1 = 0;
        var ry1 = 0;
        var dat = path;
        this.editPathMath = new EditPathMath();
        var getFrame = this.editPathMath.setEditDraw(dat);
        var frameA = getFrame.vedit;
        var nn = frameA.length;
        var sx1 = 0;
        var sy1 = 0;
        for (var i = 0; i < nn; i++) {
            sx1 = sx1 + frameA[i].x1;
            sy1 = sy1 + frameA[i].y1;
        }
        rx1 = sx1 / nn;
        ry1 = sy1 / nn;
        return [rx1, ry1];
    };
    DrawPaths.prototype.textCenter = function (def, path) {
        var w1 = 0;
        var h1 = 0;
        if (path !== undefined) {
            var aa = this.getCentroid1(path);
            w1 = aa[0];
            h1 = aa[1] + this.fontSize / 4;
        }
        var textTrans = " translate(" + w1 + "," + h1 + ") ";
        return textTrans;
    };
    DrawPaths.prototype.getCentroid = function (def) {
        var defv = def.split(" ");
        var n = defv.length;
        var p = false;
        var x1 = 0;
        var y1 = 0;
        var x2 = 0;
        var y2 = 0;
        var j = 0;
        var vedit = [];
        var type = "";
        for (var i = 0; i < n; i++) {
            var aa = [];
            if (defv[i] === "M" || defv[i] === "l") {
                type = defv[i];
                p = true;
                aa[0] = Math.ceil(defv[i + 1]);
                aa[1] = Math.ceil(defv[i + 2]);
                def = aa;
            }
            if (defv[i] === "a") {
                type = defv[i];
                var vv = defv[i + 6].split(",");
                aa[0] = Math.ceil(vv[0]);
                aa[1] = Math.ceil(vv[1]);
                p = true;
            }
            if (p) {
                x1 = x1 + aa[0];
                y1 = y1 + aa[1];
                x2 = x2 + x1;
                y2 = y2 + y2;
                vedit[j] = { x: aa[0], y: aa[1], i: j, type: type, def: def };
                j++;
            }
            p = false;
        }
        var m = vedit.length - 1;
        for (var i = 0; i < m; i++) {
            var ve1 = vedit[i];
            var i2 = i + 1;
            if (i === n - 1) {
                i2 = 0;
            }
            var ve2 = vedit[i2];
            var ve = Object.assign({}, ve1);
            var dx = (ve2.x - ve1.x) / 2;
            var dy = (ve2.y - ve1.y) / 2;
            var x0 = ve1.x + dx;
            var y0 = ve1.y + dy;
            var h1 = 0;
            if (ve2.type === "a") {
                var d = Math.sqrt(dx * dx + dy * dy);
                var r = ve2.def[2];
                var an1 = Math.abs(Math.atan(dx / dy));
                var an2 = 1.5707963267949;
                if (r > d) {
                    an2 = Math.abs(Math.asin(d / r));
                }
                else {
                    r = d;
                    ve2.def[2] = r;
                }
                h1 = r * (1 - Math.cos(an2));
                var hx = h1 * Math.cos(an1);
                var hy = h1 * Math.sin(an1);
                x0 = x0 - hx;
                y0 = y0 - hy;
            }
            ve.i = ve.i + 0.5;
            x1 = x1 + x0;
            y1 = y1 + y0;
            x2 = x2 + x1;
            y2 = y2 + y1;
        }
        x1 = x2 / (j * 2);
        y1 = y2 / (j * 2);
        return [x1, y1];
    };
    DrawPaths.prototype.getParams = function (def) {
        console.log(def);
        var defs = def.replace("a", "|a").replace("l", "|l");
        var defv = defs.split("|");
        var xy1 = defv[0].split(" ");
        var x = Number(xy1[1]);
        var y = Number(xy1[2]);
        var params = {};
        if (defv.length > 1) {
            var arc = defv[1].split(" ");
            if (arc[0] === "a") {
                var r = Number(arc[1]);
                var xy2 = arc[6].split(",");
                var x2 = Number(xy2[0]);
                var y2 = Number(xy2[1]);
                var dx = x2 - 0;
                var dy = y2 - 0;
                var d = Math.hypot(dx, dy);
                var d2 = d / 2;
                var an1 = Math.atan(y2 / x2);
                var an2 = Math.PI / 2;
                if (r > d) {
                    an2 = Math.asin(d / r);
                }
                var an4 = Math.asin(d2 / r);
                var h = r * (1 - Math.cos(an4));
                var ap = an4 * 2;
                var p = 2 * Math.PI * r;
                var l = ap * r;
                params = { h: h, d: d, ap: ap, an1: an1, an2: an2, r: r, p: p, l: l, dx: dx, dy: dy, arc: arc };
            }
        }
        var co = { x: x, y: y, params: params };
        return co;
    };
    DrawPaths.prototype.getCorner = function (def) {
        var defv = def.split(" ");
        var n = defv.length;
        var co = { w: 0, n: 0, e: 0, s: 0 };
        var p = false;
        var x1 = 0;
        var y1 = 0;
        var x2 = 0;
        var y2 = 0;
        var j = 0;
        var vedit = [];
        var type = "";
        for (var i = 0; i < n; i++) {
            var aa = [];
            if (defv[i] === "M" || defv[i] === "l") {
                type = defv[i];
                p = true;
                aa[0] = Math.ceil(defv[i + 1]);
                aa[1] = Math.ceil(defv[i + 2]);
                def = aa;
            }
            if (defv[i] === "a") {
                type = defv[i];
                var vv = defv[i + 6].split(",");
                aa[0] = Math.ceil(vv[0]);
                aa[1] = Math.ceil(vv[1]);
                p = true;
            }
            if (p) {
                x1 = x1 + aa[0];
                y1 = y1 + aa[1];
                if (x1 < co.w) {
                    co.w = x1;
                }
                if (y1 < co.n) {
                    co.n = y1;
                }
                if (x1 > co.e) {
                    co.e = x1;
                }
                if (y1 > co.s) {
                    co.s = y1;
                }
                x2 = x2 + x1;
                y2 = y2 + y2;
                vedit[j] = { x: aa[0], y: aa[1], i: j, type: type, def: def };
                j++;
            }
            p = false;
        }
        var m = vedit.length - 1;
        for (var i = 0; i < m; i++) {
            var ve1 = vedit[i];
            var i2 = i + 1;
            if (i === n - 1) {
                i2 = 0;
            }
            var ve2 = vedit[i2];
            var ve = Object.assign({}, ve1);
            var dx = (ve2.x - ve1.x) / 2;
            var dy = (ve2.y - ve1.y) / 2;
            var x0 = ve1.x + dx;
            var y0 = ve1.y + dy;
            var h1 = 0;
            if (ve2.type === "a") {
                var d = Math.sqrt(dx * dx + dy * dy);
                var r = ve2.def[2];
                var an1 = Math.abs(Math.atan(dx / dy));
                var an2 = 1.5707963267949;
                if (r > d) {
                    an2 = Math.abs(Math.asin(d / r));
                }
                else {
                    r = d;
                    ve2.def[2] = r;
                }
                h1 = r * (1 - Math.cos(an2));
                var hx = h1 * Math.cos(an1);
                var hy = h1 * Math.sin(an1);
                x0 = x0 - hx;
                y0 = y0 - hy;
            }
            ve.i = ve.i + 0.5;
            x1 = x1 + x0;
            y1 = y1 + y0;
            x2 = x2 + x1;
            y2 = y2 + y1;
        }
        x1 = x2 / (j * 2);
        y1 = y2 / (j * 2);
        return co;
    };
    return DrawPaths;
}());
export { DrawPaths };
