










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";

@Component
export default class BookingTemplate extends Vue {
  @Prop() templateModel!: any;
  @Prop() highLightField!: string;
  @Prop() highLightValues!: []; // it highligths all bookings than have the highlightfield in highligtvalues

  booking: any = {};

  mounted() {
    this.booking = this.templateModel.appointmentData;
  }

  getDate(date: string) {
    if (date === undefined) {
      return;
    }
    var sysDate = DateHelper.toSystemDate(date) as Date;
    return DateHelper.toCultureTimeString(sysDate);
  }

  getCss() {
    if (this.booking.bookingId === undefined) {
      return;
    }
    if (
      this.highLightValues !== undefined &&
      this.highLightValues.length !== 0
    ) {
      var found = this.highLightValues.findIndex(
        (y) => y === this.booking[this.highLightField]
      );
      if (found === -1) {
        return `background-color:#848484; color: white`;
      }
    }

    if (this.booking.parentName === "Training") {
      return "filter: brightness(85%)";
    }
  }
}
