import { render, staticRenderFns } from "./bookingTemplate.vue?vue&type=template&id=b0213474&scoped=true&"
import script from "./bookingTemplate.vue?vue&type=script&lang=ts&"
export * from "./bookingTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./bookingTemplate.vue?vue&type=style&index=0&id=b0213474&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0213474",
  null
  
)

export default component.exports