var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { ModifyMode } from "../core/constants";
import { ShopService } from "../core/services/shopService";
import InputText from "../components/form/inputText.vue";
import InputPassword from "../components/form/inputPassword.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import { PaymentMethodType } from "../core/constants";
import { Loader } from "../core/helpers/loaderHelper";
import Popup from "../components/popup/popup.vue";
import Checkbox from "../components/form/checkBox.vue";
import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);
var CheckoutPage = /** @class */ (function (_super) {
    __extends(CheckoutPage, _super);
    function CheckoutPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.settings = {};
        _this.error = "";
        _this.ok = "";
        _this.data = null;
        _this.transport = null;
        _this.errors = {};
        _this.hasErrors = false;
        _this.paymentMethodId = null;
        _this.tokenId = null;
        _this.saveCreditCard = false;
        _this.acceptConditions = false;
        _this.okRss = false;
        _this.showPopUpLegal = false;
        _this.showPopUpGeneral = false;
        _this.showPopUpPrivacy = false;
        _this.showPopUpCookies = false;
        _this.promoCode = "";
        _this.errorPromo = "";
        _this.okPromo = "";
        _this.discount = 0;
        _this.textOkButton = "";
        _this.privacyPolicyIntro = "";
        _this.privacyPolicyFull = "";
        _this.openHours = "";
        _this.cookiesPolicy = "";
        _this.cookiesBanner = "";
        _this.generalConditions = "";
        _this.paypal = {};
        _this.paypalId = "";
        _this.paypalIsLoaded = false;
        _this.paypalPaymentMethod = undefined;
        _this.currency = "EUR";
        _this.showCreditCardForm = false;
        _this.name = "";
        _this.cardnumber = "";
        _this.expirationdate = "";
        _this.securitycode = "";
        _this.netPayTokenInfo = null;
        return _this;
    }
    CheckoutPage.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var i, _i, _a, saleItem, cid, sid, url, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.settings = this.$store.state.settings;
                        if (this.$store.state.cart === null ||
                            this.$store.state.cart === undefined) {
                            return [2 /*return*/, this.$router.push("/")];
                        }
                        if (this.$store.state.cart.count === 0) {
                            return [2 /*return*/, this.$router.push("/")];
                        }
                        i = 1;
                        for (_i = 0, _a = this.$store.state.cart.sItems; _i < _a.length; _i++) {
                            saleItem = _a[_i];
                            saleItem.saleId = -1 * i; // saleId tmp to identify
                            i++;
                        }
                        cid = null;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        url = cid == null
                            ? Settings.HostName + "/api/shop/checkout-info"
                            : Settings.HostName + "/api/shop/checkout-info-by-customer";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                customerId: cid,
                                saleItems: this.$store.state.cart.sItems,
                                saleIds: this.$store.state.cart.sales.map(function (y) { return y.id; }),
                            })];
                    case 1:
                        data = _b.sent();
                        if (data == null || data === undefined) {
                            console.error("Internal server error getting checkout info " + cid);
                            if (data.ok === false) {
                                console.error(data.errorMsg);
                            }
                            return [2 /*return*/];
                        }
                        this.data = data.data;
                        if (data.data === null) {
                            console.error(data.errorMsg);
                            return [2 /*return*/];
                        }
                        if (this.data.transports !== null &&
                            this.data.transports !== undefined &&
                            this.data.transports.length > 0) {
                            this.transportRowClick(this.data.transports[0]);
                        }
                        this.okRss = this.data.okRss !== null ? this.data.okRss : false;
                        this.acceptConditions =
                            this.data.acceptConditions !== null ? this.data.acceptConditions : false;
                        this.textOkButton = this.$t("Ok").toString();
                        this.getSettings();
                        this.setUpPayPal();
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.setUpPayPal = function () {
        this.paypalPaymentMethod = this.data.paymentMethods.find(function (y) { return y.type === PaymentMethodType.PayPal; });
        if (this.paypalPaymentMethod !== undefined) {
            this.paypalId = this.data.payPalId;
            this.currency = this.data.currency;
            this.loadPaypal();
        }
    };
    CheckoutPage.prototype.setCreditCardInfo = function (info) {
        this.error = "";
        this.name = info.name;
        this.cardnumber = info.cardnumber;
        this.expirationdate = info.expirationdate;
        this.securitycode = info.securitycode;
    };
    CheckoutPage.prototype.getSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = Settings.HostName + "/api/czconfigs/legal-settings";
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _a.sent();
                        if (data == null || data.data == null || !data.ok) {
                            console.error("Internal server error getting cz legal settings");
                            return [2 /*return*/];
                        }
                        this.privacyPolicyIntro = data.data.privacyPolicyIntro;
                        this.privacyPolicyFull = data.data.privacyPolicyFull;
                        this.openHours = data.data.openHours;
                        this.cookiesPolicy = data.data.cookiesPolicy;
                        this.cookiesBanner = data.data.cookiesBanner;
                        this.generalConditions = data.data.generalConditions;
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.validatePromo = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorPromo = "";
                        this.okPromo = "";
                        e.preventDefault();
                        if (this.promoCode === "" ||
                            this.promoCode === undefined ||
                            this.promoCode === null) {
                            return [2 /*return*/];
                        }
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        url = Settings.HostName + "/api/promos/validate";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                code: this.promoCode,
                                customerId: cid,
                                saleItems: this.getSaleItems(cid),
                                saleIds: this.$store.state.cart.sales.map(function (v) {
                                    return v.id;
                                }),
                            })];
                    case 1:
                        data = _a.sent();
                        if (data == null ||
                            data === undefined ||
                            (data.ok === false && data.errorMsg === null)) {
                            this.errorPromo = this.$t("Sorry, the code is not valid").toString();
                            return [2 /*return*/];
                        }
                        if (data.ok === false) {
                            this.errorPromo = data.errorMsg;
                            return [2 /*return*/];
                        }
                        this.okPromo = this.$t("Disccount Applied").toString();
                        this.discount = data.data.discount;
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.getSaleItems = function (cId) {
        if (cId === void 0) { cId = null; }
        var saleItems = this.$store.state.cart.sItems.filter(function (y) { return y.units > 0; });
        if (this.promoCode === "") {
            return saleItems;
        }
        // one saleitem per line to calculate promos
        var saleItemsDto = [];
        for (var _i = 0, saleItems_1 = saleItems; _i < saleItems_1.length; _i++) {
            var sItem = saleItems_1[_i];
            for (var j = 0; j < sItem.units; j++) {
                var unitOneSaleItem = Object.assign({}, sItem);
                unitOneSaleItem.units = 1;
                unitOneSaleItem.customerId = cId;
                unitOneSaleItem.buyerCustomerId = cId;
                saleItemsDto.push(unitOneSaleItem);
            }
        }
        return saleItemsDto;
    };
    CheckoutPage.prototype.getTotal = function () {
        var total = this.transport !== null && this.transport !== undefined
            ? this.transport.product.grossPrice +
                this.$store.state.cart.total -
                this.discount
            : this.$store.state.cart.total - this.discount;
        return this.formatPrice(total);
    };
    CheckoutPage.prototype.selectPaymentMethod = function (id, tokenId) {
        this.paymentMethodId = id;
        this.tokenId = tokenId;
    };
    CheckoutPage.prototype.checkoutWithCreditCard = function (e) {
        var _this = this;
        e.preventDefault();
        if (Format.IsNull(this.name) ||
            Format.IsNull(this.cardnumber) ||
            Format.IsNull(this.expirationdate) ||
            Format.IsNull(this.securitycode)) {
            this.error = this.$t("Please fill all fields").toString();
            return;
        }
        var pMethod = this.data.paymentMethods.find(function (y) { return y.id === _this.paymentMethodId; });
        if (pMethod === undefined) {
            console.error("not payment method found for id " + this.paymentMethodId);
            return;
        }
        if (pMethod.type === PaymentMethodType.NetPay) {
            return this.netPay();
        }
    };
    CheckoutPage.prototype.netPay = function () {
        var expiry = this.expirationdate.split("/");
        var cardInformation = {
            cardNumber: this.cardnumber,
            expMonth: expiry[0],
            expYear: expiry[1],
            cvv2: this.securitycode,
        };
        Loader.Loading("netPaycheckoutBtn", this.$refs.netPaycheckoutBtn);
        var fake = this;
        fake
            .$loadScript("https://docs.netpay.mx/cdn/v1.3/netpay.min.js")
            .then(function () {
            var NetPay = self.NetPay;
            NetPay.setApiKey(fake.data.netPayPublicKey);
            NetPay.setSandboxMode(fake.data.netPayProdMode === false);
            NetPay.card.validateNumber(cardInformation.cardNumber);
            NetPay.card.validateExpiry(cardInformation.expMonth, cardInformation.expYear);
            NetPay.card.validateCVV(cardInformation.cvv2, cardInformation.cardNumber);
            NetPay.card.validateNumberLength(cardInformation.cardNumber);
            NetPay.token.create(cardInformation, success, error);
            function success(e) {
                fake.netPayTokenInfo = JSON.parse(e.message.data);
                fake.netPayTokenInfo.ccv = cardInformation.cvv2;
                fake.checkout(null, true);
                Loader.Unloading("netPaycheckoutBtn", fake.$refs.netPaycheckoutBtn);
                // fake.showCreditCardForm = false;
            }
            function error(e) {
                Loader.Unloading("netPaycheckoutBtn", fake.$refs.netPaycheckoutBtn);
                fake.error = e.message;
            }
        });
    };
    CheckoutPage.prototype.checkout = function (e, fromCreditCardForm) {
        if (fromCreditCardForm === void 0) { fromCreditCardForm = false; }
        return __awaiter(this, void 0, void 0, function () {
            var pMethod, cid, sid, url, data, errorMsg;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (e !== null) {
                            e.preventDefault();
                        }
                        this.cleanErrors();
                        this.checkErrors();
                        if (this.error !== "" &&
                            (this.error !== null || this.error !== undefined)) {
                            return [2 /*return*/];
                        }
                        pMethod = this.data.paymentMethods.find(function (y) { return y.id === _this.paymentMethodId; });
                        if (pMethod === undefined) {
                            console.error("not payment method found for id " + this.paymentMethodId);
                            return [2 /*return*/];
                        }
                        if (fromCreditCardForm === false &&
                            pMethod.type === PaymentMethodType.NetPay &&
                            this.tokenId === null) {
                            this.showCreditCardForm = true;
                            return [2 /*return*/];
                        }
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        url = cid == 0
                            ? Settings.HostName + "/api/shop/checkout"
                            : Settings.HostName + "/api/shop/checkout-customer";
                        Loader.Loading("checkoutBtn");
                        return [4 /*yield*/, new CrudService().httpPost(url, this.buildCheckoutParams(cid))];
                    case 1:
                        data = _a.sent();
                        Loader.Unloading("checkoutBtn");
                        errorMsg = this.$t("Ups! Tecnical problems").toString() +
                            ". " +
                            this.$t("Please try again in a few minutes").toString();
                        if (data == null || data === undefined) {
                            console.error("Internal server error checkout " + cid);
                            this.error = errorMsg;
                            return [2 /*return*/];
                        }
                        if (data.ok === false) {
                            this.error = data.model.errorMsg !== "" ? data.model.errorMsg : errorMsg;
                            return [2 /*return*/];
                        }
                        if (data.model !== null && data.model.redsysData !== undefined) {
                            this.redirectToRedsys(data.model.redsysData);
                            return [2 /*return*/];
                        }
                        if (data.model !== null && data.model.paypal !== undefined) {
                            return [2 /*return*/, data.model.paypal]; // paypal create order
                        }
                        if (data.ok === true) {
                            new ShopService().removeCart();
                            return [2 /*return*/, this.$router.push("/cz/ok")];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.buildCheckoutParams = function (cid) {
        return {
            saleItems: this.getSaleItems(cid),
            saleIds: this.$store.state.cart.sales.map(function (y) { return y.id; }),
            paymentMethodId: this.paymentMethodId,
            tokenId: this.tokenId,
            promoCode: this.promoCode,
            shopTransportId: Format.IsNull(this.transport) ? null : this.transport.id,
            saveCreditCard: this.saveCreditCard,
            guid: this.$store.state.cart.guid,
            tpvOnlineInfoTemp: this.netPayTokenInfo !== null
                ? JSON.stringify(this.netPayTokenInfo)
                : "",
            customer: {
                id: cid,
                name: this.data.name,
                surname: this.data.surname,
                addressLine1: this.data.addressLine1,
                city: this.data.city,
                town: this.data.town,
                country: this.data.country,
                postalCode: this.data.postalCode,
                email: this.data.email,
                phone: this.data.phone,
                password: this.data.password,
                okRss: this.okRss,
                acceptConditions: this.acceptConditions,
            },
        };
    };
    CheckoutPage.prototype.createPayPalButtons = function () {
        var _this = this;
        var fake = this;
        fake
            .$loadScript("https://www.paypal.com/sdk/js?client-id=" +
            this.paypalId +
            "&currency=" +
            this.currency +
            "&intent=authorize&disable-funding=mercadopago,credit,card", {
            "data-sdk-integration-source": "button-factory",
        })
            .then(function () {
            _this.paypal = window["paypal"];
            _this.createPaypalContainerIfNotAvailable();
            var btns = _this.paypal.Buttons({
                style: {
                    shape: "rect",
                    color: "gold",
                    layout: "vertical",
                    label: "paypal",
                    size: "responsive",
                },
                onClick: function () {
                    _this.paymentMethodId = _this.paypalPaymentMethod.id;
                },
                createOrder: function (data) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, this.checkout(null)];
                    });
                }); },
                onApprove: function (data, actions) { return __awaiter(_this, void 0, void 0, function () {
                    var s;
                    return __generator(this, function (_a) {
                        Loader.Loading("checkoutBtn");
                        s = this;
                        actions.order
                            .authorize()
                            .then(function (authorization) {
                            // Get the authorization id
                            var authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;
                            // Call your server to validate and capture the transaction
                            var url = Settings.HostName + "/api/paypal/callback";
                            return new CrudService().httpPost(url, {
                                orderId: data.orderID,
                                authorizationId: authorizationID,
                            });
                        })
                            .then(function (res) {
                            Loader.Unloading("checkoutBtn");
                            if (res === null) {
                                s.error = "Internal server error. Please try again";
                                actions.close();
                                return;
                            }
                            if (res.ok === false) {
                                s.error = res.errorMsg;
                                console.error(res.errorMsg);
                                actions.close();
                                return;
                            }
                            if (res.ok === true) {
                                new ShopService().removeCart();
                                actions.redirect(window.location.protocol +
                                    "//" +
                                    window.location.host +
                                    "/cz/ok");
                            }
                        });
                        return [2 /*return*/];
                    });
                }); },
                onError: function (err) {
                    console.error(err);
                },
            });
            setTimeout(function () {
                btns.render("#paypal-button-container");
            }, 3);
        });
    };
    CheckoutPage.prototype.createPaypalContainerIfNotAvailable = function () {
        if (document.getElementById("paypal-button-container")) {
            return;
        }
        else {
            var superParent = document.getElementById("payPalParent");
            var parent = document.createElement("div");
            parent.setAttribute("id", "payPalButtons");
            superParent === null || superParent === void 0 ? void 0 : superParent.appendChild(parent);
            var child = document.createElement("div");
            child.setAttribute("id", "paypal-button-container");
            parent === null || parent === void 0 ? void 0 : parent.appendChild(child);
        }
    };
    CheckoutPage.prototype.loadPaypal = function () {
        if (!this.paypalIsLoaded) {
            this.createPayPalButtons();
            this.paypalIsLoaded = true;
        }
    };
    CheckoutPage.prototype.payVoucher = function (sale, saleItem, saleId, btnDomId) {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, voucherSelectedDom, vouchers, paymentMethodId, voucherId, tokenId, _i, vouchers_1, v, url, data, errorMsg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cleanErrors();
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        voucherSelectedDom = document.getElementById("__voucher_for_" + saleId);
                        if (voucherSelectedDom === null) {
                            return [2 /*return*/];
                        }
                        vouchers = this.data.vouchers.find(function (y) { return y.saleId === saleId; })
                            .vouchers;
                        paymentMethodId = -1;
                        voucherId = -1;
                        tokenId = null;
                        if (voucherSelectedDom.value === "") {
                            // is only one option
                            paymentMethodId = vouchers[0].id;
                            voucherId = vouchers[0].voucher.id;
                        }
                        else {
                            voucherId = Number(voucherSelectedDom.value);
                            for (_i = 0, vouchers_1 = vouchers; _i < vouchers_1.length; _i++) {
                                v = vouchers_1[_i];
                                if (v.voucher.id === voucherId) {
                                    paymentMethodId = v.id;
                                }
                            }
                        }
                        url = Settings.HostName + "/api/shop/checkout-customer";
                        Loader.Loading(btnDomId);
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                saleIds: sale !== null && sale !== undefined ? [sale.id] : null,
                                saleItems: saleItem !== null && saleItem !== undefined ? [saleItem] : null,
                                paymentMethodId: paymentMethodId,
                                voucherId: voucherId,
                                customer: {
                                    id: cid,
                                },
                            })];
                    case 1:
                        data = _a.sent();
                        Loader.Unloading(btnDomId);
                        errorMsg = this.$t("Ups! Tecnical problems. Please try again in a few minutes").toString();
                        if (data == null || data === undefined) {
                            console.error("Internal server error checkout " + cid);
                            this.error = errorMsg;
                            return [2 /*return*/];
                        }
                        if (data.ok === false || data.errors !== undefined) {
                            this.error = data.model.errorMsg !== "" ? data.model.errorMsg : errorMsg;
                            return [2 /*return*/];
                        }
                        if (saleItem !== null && saleItem !== undefined) {
                            new ShopService().removeSaleItemFromCart(saleItem);
                        }
                        if (saleId > 0) {
                            new ShopService().removeSaleFromCart(saleId, false);
                        }
                        if (this.$store.state.cart.count === 0) {
                            return [2 /*return*/, this.$router.push("/cz/ok")];
                        }
                        location.href = "/checkout";
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.redirectToRedsys = function (redsysData) {
        var data = redsysData.data;
        var form = this.$refs.redsysFrm;
        form.action = redsysData.url;
        var sVersion = this.$refs.ds_SignatureVersion;
        sVersion.value = data.ds_SignatureVersion;
        var sign = this.$refs.ds_Signature;
        sign.value = data.ds_Signature;
        var params = this.$refs.ds_MerchantParameters;
        params.value = data.ds_MerchantParameters;
        form.submit();
    };
    CheckoutPage.prototype.cleanErrors = function () {
        this.hasErrors = false;
        this.errors.name = "";
        this.errors.accountlastname = "";
        this.errors.surname = "";
        this.errors.addressLine1 = "";
        this.errors.city = "";
        this.errors.town = "";
        this.errors.country = "";
        this.errors.postalCode = "";
        this.errors.email = "";
        this.errors.phone = "";
        this.errors.password1 = "";
        this.errors.password2 = "";
        this.error = "";
    };
    CheckoutPage.prototype.isNull = function (value) {
        return Format.IsNull(value);
    };
    CheckoutPage.prototype.checkErrors = function () {
        var msg = this.$t("Check required fields").toString() + "<br> ";
        var transportRequired = this.$store.state.cart.sItems.some(function (y) { return y.units > 0 && y.product.transportRequired === true; });
        if (transportRequired === true) {
            if (Format.IsNull(this.data.name)) {
                this.errors.name = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.surname)) {
                this.errors.surname = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.addressLine1)) {
                this.errors.addressLine1 = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.city)) {
                this.errors.city = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.town)) {
                this.errors.town = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.country)) {
                this.errors.country = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.postalCode)) {
                this.errors.postalCode = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.email)) {
                this.errors.email = msg;
                this.error = msg;
            }
            if (Format.IsNull(this.data.phone)) {
                this.errors.phone = msg;
                this.error = msg;
            }
        }
        if (this.$store.state.loggedIn === false) {
            if (Format.IsNull(this.data.password)) {
                this.errors.password1 = msg;
                this.error = msg;
            }
            if (this.data.password !== this.data.password2) {
                this.errors.password2 = this.$t("Password does not match");
                this.errors.password1 = this.$t("Password does not match");
                this.error = this.$t("Password does not match").toString();
            }
        }
        if (this.transport === null && transportRequired === true) {
            this.error += this.$t("Please select transport").toString() + "<br> ";
        }
        if (this.$store.state.cart.count === 0) {
            this.error += this.$t("Your basket is empty!") + "<br> ";
        }
        if (this.paymentMethodId === null) {
            this.error +=
                this.$t("Please select a payment method").toString() + "<br> ";
        }
        if (this.acceptConditions === false) {
            this.error +=
                this.$t("Please accept our legal conditions").toString() + "<br> ";
        }
    };
    CheckoutPage.prototype.removeSale = function (e, saleId) {
        e.preventDefault();
        new ShopService().removeSaleFromCart(saleId);
    };
    CheckoutPage.prototype.getImagePath = function (img) {
        if (img === null || img === undefined || img === "") {
            return this.settings.Uploads + "/" + this.settings.Mainlogo;
        }
        return this.settings.Uploads + "/" + img;
    };
    CheckoutPage.prototype.formatPrice = function (price) {
        return Format.formatCurrency(price);
    };
    CheckoutPage.prototype.transportRowClick = function (transport) {
        var dom = document.getElementById("trans_" + transport.id);
        if (dom !== null && dom !== undefined)
            dom.click();
        this.transport = transport;
    };
    CheckoutPage.prototype.increment = function (sItem) {
        new ShopService().modifyUnits(ModifyMode.Increment, sItem, 1);
    };
    CheckoutPage.prototype.decrement = function (sItem) {
        new ShopService().modifyUnits(ModifyMode.Decrement, sItem, 1);
    };
    CheckoutPage.prototype.modify = function (sItem) {
        var units = Number(sItem.units);
        if (isNaN(units)) {
            return;
        }
        new ShopService().modifyUnits(ModifyMode.Exact, sItem, units);
    };
    CheckoutPage.prototype.showSaveCreditCard = function (pMethod) {
        return pMethod.allowTokenization === true && pMethod.tokenId === null;
    };
    CheckoutPage.prototype.getPaymentMethodImage = function (pMethod) {
        if (pMethod.tokenId !== null) {
            return "/img/sslPage.png";
        }
        if (pMethod.type === PaymentMethodType.Redsys) {
            return "/img/redsys.png";
        }
        if (pMethod.type === PaymentMethodType.NetPay) {
            return "/img/netpay.png";
        }
    };
    CheckoutPage.prototype.isPayPal = function (pMethod) {
        return pMethod.type === PaymentMethodType.PayPal;
    };
    CheckoutPage = __decorate([
        Component({
            components: { InputText: InputText, InputPassword: InputPassword, Popup: Popup, Checkbox: Checkbox, CreditCardForm: CreditCardForm },
        })
    ], CheckoutPage);
    return CheckoutPage;
}(Vue));
export default CheckoutPage;
