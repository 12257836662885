var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import { Format } from "../core/helpers/formatHelper";
import Vue from "vue";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";
import { ShopService } from "../core/services/shopService";
var MyFeesPage = /** @class */ (function (_super) {
    __extends(MyFeesPage, _super);
    function MyFeesPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = null;
        _this.error = "";
        _this.infoReturns = null;
        _this.reload = 1;
        _this.settings = {};
        _this.debts = null;
        _this.salesToPay = [];
        return _this;
    }
    MyFeesPage.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.settings = store.state.settings;
                        cid = 0;
                        try {
                            cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
                        }
                        catch (_b) {
                            console.error("not customer logged found");
                        }
                        return [4 /*yield*/, this.loadData(cid)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyFeesPage.prototype.loadData = function (customerId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = Settings.HostName + "/api/publiccustomerperiodicsales/customer-fees/" + customerId;
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _a.sent();
                        if (data === null || data.ok === false) {
                            console.error("Internal server error getting Your Fees");
                            this.error = this.$t("Internal Server Error").toString();
                            return [2 /*return*/];
                        }
                        this.data = data.model;
                        return [2 /*return*/];
                }
            });
        });
    };
    MyFeesPage.prototype.selectDebt = function (debt) {
        var found = this.salesToPay.find(function (y) { return y.saleNumber === debt.saleNumber; });
        if (found === undefined) {
            this.salesToPay.push(debt);
            return;
        }
        this.salesToPay = this.salesToPay.filter(function (item) { return item.saleNumber !== found.saleNumber; });
    };
    MyFeesPage.prototype.addToCart = function (e) {
        e.preventDefault();
        var shopService = new ShopService();
        for (var _i = 0, _a = this.salesToPay; _i < _a.length; _i++) {
            var sale = _a[_i];
            shopService.addSaleToCart(sale, e.currentTarget);
        }
    };
    MyFeesPage.prototype.formatPrice = function (price) {
        return Format.formatCurrency(price);
    };
    MyFeesPage.prototype.getDateFormatted = function (dt) {
        return DateHelper.formatDate(new Date(dt)).split(" ")[0];
    };
    MyFeesPage.prototype.showAddSale = function () {
        if (Format.IsNull(this.data) || this.data.length === 0) {
            return false;
        }
        return this.data.map(function (a) { return a.fees.length; }).reduce(function (a, b) { return a + b; }) > 0;
    };
    MyFeesPage = __decorate([
        Component({
            components: {
                CancelReturnsInfo: CancelReturnsInfo,
            },
        })
    ], MyFeesPage);
    return MyFeesPage;
}(Vue));
export default MyFeesPage;
