var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";
var DataCellToolTipTemplate = /** @class */ (function (_super) {
    __extends(DataCellToolTipTemplate, _super);
    function DataCellToolTipTemplate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.error = "";
        _this.bookingBlock = null;
        _this.customerSelecteds = [];
        return _this;
    }
    DataCellToolTipTemplate.prototype.mounted = function () {
        this.bookingBlock = this.templateTooltipModel.appointmentData;
        if (this.urlClick !== undefined &&
            this.urlClick !== "#" &&
            this.urlClick !== "") {
            var url = this.urlClick + "/" + this.bookingBlock.bookingId;
            if (this.returnUrl !== undefined && this.returnUrl !== "") {
                url += "?returnUrl=" + this.returnUrl;
            }
            return this.$router.push(url);
        }
    };
    DataCellToolTipTemplate.prototype.stopPropagation = function (e) {
        e.stopPropagation();
        if (this.bookingBlock.parentName === "Training") {
            return this.$router.push("/trainings/" + this.bookingBlock.bookingId);
        }
        if (this.bookingBlock.parentName === "Team") {
            return this.$router.push("/matches/" + this.bookingBlock.bookingId);
        }
    };
    DataCellToolTipTemplate.prototype.getTime = function () {
        if (this.bookingBlock === null) {
            return;
        }
        var ds = DateHelper.toSystemDate(this.bookingBlock.startDate);
        var de = DateHelper.toSystemDate(this.bookingBlock.endDate);
        if (ds !== undefined && de !== undefined) {
            return (DateHelper.toCultureTimeString(ds) +
                " - " +
                DateHelper.toCultureTimeString(de));
        }
    };
    DataCellToolTipTemplate.prototype.getBookingTypeName = function () {
        if (this.bookingBlock === null) {
            return;
        }
        return this.bookingBlock.bookingTypeName;
    };
    __decorate([
        Prop()
    ], DataCellToolTipTemplate.prototype, "scheduler", void 0);
    __decorate([
        Prop()
    ], DataCellToolTipTemplate.prototype, "templateTooltipModel", void 0);
    __decorate([
        Prop()
    ], DataCellToolTipTemplate.prototype, "timeOutId", void 0);
    __decorate([
        Prop()
    ], DataCellToolTipTemplate.prototype, "urlClick", void 0);
    __decorate([
        Prop()
    ], DataCellToolTipTemplate.prototype, "returnUrl", void 0);
    __decorate([
        Emit("mounted")
    ], DataCellToolTipTemplate.prototype, "mounted", null);
    DataCellToolTipTemplate = __decorate([
        Component
    ], DataCellToolTipTemplate);
    return DataCellToolTipTemplate;
}(Vue));
export default DataCellToolTipTemplate;
