import { render, staticRenderFns } from "./shopPage.vue?vue&type=template&id=1fccd2cd&scoped=true&"
import script from "./shopPage.vue?vue&type=script&lang=ts&"
export * from "./shopPage.vue?vue&type=script&lang=ts&"
import style0 from "./shopPage.vue?vue&type=style&index=0&id=1fccd2cd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fccd2cd",
  null
  
)

export default component.exports