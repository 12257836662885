var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-content"},[(_vm.data !== null)?_c('div',[_vm._l((_vm.data.resourceGroups),function(rgroup){return _c('span',{key:rgroup.resourceGroup.name,ref:("group_" + (rgroup.resourceGroup.id)),refInFor:true,staticClass:"cur-p bgcH-grey-200 p-10 m-5 tt-u",class:_vm.group.resourceGroup.id === rgroup.resourceGroup.id
          ? 'c-teal-fortia'
          : '',on:{"click":function($event){return _vm.selectGroup(rgroup.resourceGroup.id)}}},[_vm._v(" "+_vm._s(rgroup.resourceGroup.name)+" ")])}),_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"neomorph-button-xsrad"},[_c('div',{staticClass:"form-group pR-0"},[_c('DxScheduler',{key:_vm.reload,attrs:{"data-source":_vm.dataSource,"current-date":_vm.currentDate,"shade-until-current-time":true,"views":_vm.views,"start-day-hour":_vm.group.start,"end-day-hour":_vm.getEndDayHour(_vm.group),"current-view":_vm.getCurrentView(_vm.group),"first-day-of-week":1,"groups":_vm.groups,"show-all-day-panel":true,"time-zone":_vm.timezone(),"cell-duration":_vm.group.resourceGroup.intervalMinutes === 0 ||
              _vm.group.resourceGroup.intervalMinutes === undefined ||
              _vm.group.resourceGroup.intervalMinutes == null
                ? 30
                : _vm.group.resourceGroup.intervalMinutes,"data-cell-template":"dataCellTemplate","time-cell-template":"timeCellTemplate","appointment-template":"bookingTemplate","appointment-tooltip-template":"dataCellToolTipTemplate","onCellClick":_vm.onCellClick,"onAppointmentDblClick":_vm.onAppointmentDblClick,"onOptionChanged":_vm.onOptionChanged,"on-content-ready":_vm.onContentReady,"height":_vm.getHeight()},scopedSlots:_vm._u([{key:"dataCellTemplate",fn:function(ref){
                var cellData = ref.data;
return [_c('DataCell',{attrs:{"cell-data":cellData,"resources":_vm.group.resources}})]}},{key:"timeCellTemplate",fn:function(ref){
                var cellData = ref.data;
return [_c('TimeCell',{attrs:{"cell-data":cellData}})]}},{key:"dataCellToolTipTemplate",fn:function(ref){
                var data = ref.data;
return [_c('DataCellToolTipTemplate',{attrs:{"template-tooltip-model":data,"scheduler":_vm.scheduler,"timeOutId":_vm.timeOutId,"urlClick":_vm.onBookingClickUrl,"returnUrl":_vm.returnUrl},on:{"refreshDataSource":_vm.getBookings,"mounted":_vm.restartRefreshTimeOut}})]}},{key:"bookingTemplate",fn:function(ref){
                var data = ref.data;
return [_c('BookingTemplate',{attrs:{"scheduler":_vm.scheduler,"template-model":data,"highLightField":_vm.highLightField,"highLightValues":_vm.highLightValues}})]}}],null,false,1069623444)},[_c('DxEditing',{attrs:{"allow-deleting":false,"allow-adding":false,"allow-updating":false}}),(_vm.group.resources !== null && _vm.group.resources !== undefined)?_c('DxResource',{attrs:{"data-source":_vm.getUnicResources(_vm.group.resources),"field-expr":"resourceId"}}):_vm._e(),_vm._v(" --> ")],1)],1)])])],2):_vm._e(),(_vm.error != '')?_c('div',{staticClass:"wrapper alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.bookingTypesPopUp)?_c('Popup',{staticClass:"popupMd",attrs:{"errorMsg":_vm.saveError,"showOkButton":false},on:{"close":function($event){_vm.bookingTypesPopUp = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t("Booking Type")))]),_c('div',{staticClass:"form-group",attrs:{"slot":"body"},slot:"body"},[_vm._l((Object.keys(_vm.data.bookingTypes.byCategory)),function(category,key){return _c('span',{key:("tab_" + key)},[_c('button',{staticClass:"neomorph-button",on:{"click":function($event){return _vm.setCategorySelected(category)}}},[_vm._v(" "+_vm._s(category)+" ")]),(_vm.categorySelected === category)?_c('span',_vm._l((_vm.data.bookingTypes.byCategory[
              category
            ]),function(byCategory,key){return _c('span',{key:("cat_" + key)},[(_vm.bookingTypeAllowed(byCategory))?_c('button',{staticClass:"neomorph-button mR-5",on:{"click":function($event){return _vm.addBooking(byCategory)}}},[_vm._v(" "+_vm._s(byCategory.description)+" ")]):_vm._e()])}),0):_vm._e(),_c('br'),_c('hr')])}),_vm._l((_vm.data.bookingTypes.noCategory),function(bookingType,key){return _c('span',{key:key,attrs:{"name":("tab_" + key)}},[(_vm.bookingTypeAllowed(bookingType))?_c('button',{staticClass:"neomorph-button mR-5",on:{"click":function($event){return _vm.addBooking(bookingType)}}},[_vm._v(" "+_vm._s(bookingType.description)+" ")]):_vm._e()])})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }