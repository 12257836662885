import { render, staticRenderFns } from "./myOrderPage.vue?vue&type=template&id=04abd45c&scoped=true&"
import script from "./myOrderPage.vue?vue&type=script&lang=ts&"
export * from "./myOrderPage.vue?vue&type=script&lang=ts&"
import style0 from "./myOrderPage.vue?vue&type=style&index=0&id=04abd45c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04abd45c",
  null
  
)

export default component.exports