var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Popup from "../popup/popup.vue";
import store from "../../core/store";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { LocalStorage } from "../../core/localStorage";
import InputPassword from "../../components/form/inputPassword.vue";
import Checkbox from "../../components/form/checkBox.vue";
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openLogin = false;
        _this.email = "";
        _this.password = "";
        _this.resetPassEmail = "";
        _this.name = "";
        _this.surname = "";
        _this.registerEmail = "";
        _this.registerPass = "";
        _this.phone = "";
        _this.okRss = false;
        _this.error = "";
        _this.loginTitle = "";
        _this.loginButtonTxt = "";
        _this.loginMode = true;
        _this.registerMode = false;
        _this.resetPasswordMode = false;
        _this.resetSent = false;
        _this.registerOk = false;
        return _this;
    }
    Login.prototype.mounted = function () {
        this.openLogin = this.open;
        this.error = "";
        this.loginTitle = this.$t("Login").toString();
        this.loginButtonTxt = this.$t("Login").toString();
    };
    Login.prototype.submit = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                e.preventDefault();
                this.error = "";
                this.registerOk = false;
                if (this.resetSent) {
                    this.closeLogin();
                    return [2 /*return*/];
                }
                if (this.resetPasswordMode) {
                    this.resetPassword();
                    return [2 /*return*/];
                }
                if (this.registerMode) {
                    this.register();
                    return [2 /*return*/];
                }
                this.login();
                return [2 /*return*/];
            });
        });
    };
    Login.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, data, c;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.email.indexOf("@") === -1) {
                            this.error = this.$t("For security reasons, the user name to log into your account will be your email").toString() + '. ' +
                                this.$t("You wont be able to use your phone number any more").toString() + '. ' +
                                this.$t("Please contact the center if you need assistance").toString();
                            return [2 /*return*/];
                        }
                        url = Settings.HostName + "/api/publiccustomers/cauth";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                email: this.email,
                                password: this.password,
                            })];
                    case 1:
                        data = _a.sent();
                        if (data == null) {
                            console.error("Internal server error on login");
                            return [2 /*return*/];
                        }
                        if (data.ok === false) {
                            if (data.errorMsg !== "" &&
                                data.errorMsg !== null &&
                                data.errorMsg !== undefined) {
                                this.error = data.errorMsg;
                                if (data.redirect !== undefined && data.redirect === true) {
                                    this.resetPassModeOn();
                                }
                                return [2 /*return*/];
                            }
                            this.error = this.$t("Invalid Email or Password").toString();
                            return [2 /*return*/];
                        }
                        this.closeLogin();
                        LocalStorage.addWithTimout("ctk", data.model.token, data.model.expiry);
                        c = {
                            id: data.model.id,
                        };
                        LocalStorage.addWithTimout("cc", JSON.stringify(c), data.model.expiry);
                        if (this.$route.fullPath !== store.state.loginRedirect) {
                            location.href = store.state.loginRedirect;
                            return [2 /*return*/];
                        }
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    Login.prototype.register = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = Settings.HostName + "/api/publiccustomers/register";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                name: this.name,
                                surname: this.surname,
                                email: this.registerEmail,
                                password: this.registerPass,
                                phone: this.phone,
                                okRss: this.okRss,
                            })];
                    case 1:
                        data = _a.sent();
                        if (data == null) {
                            console.error("Internal server error on login");
                            return [2 /*return*/];
                        }
                        if (data.ok === false) {
                            this.error = data.errorMsg;
                            return [2 /*return*/];
                        }
                        this.registerOk = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    Login.prototype.resetPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.resetSent = true;
                        this.loginButtonTxt = this.$t("Close").toString();
                        url = Settings.HostName + "/api/publiccustomers/reset-password-request-cz";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                email: this.resetPassEmail,
                            })];
                    case 1:
                        data = _a.sent();
                        if (data == null) {
                            console.error("Internal server error on reset-password-request-cz");
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Login.prototype.closeLogin = function () {
        this.loginTitle = this.$t("Login").toString();
        this.loginButtonTxt = this.$t("Login").toString();
        this.error = "";
        this.loginMode = true;
        this.registerMode = false;
        this.resetPasswordMode = false;
        this.registerOk = false;
        this.openLogin = false;
        this.resetSent = false;
        LocalStorage.delete("loginModeOn");
        store.commit("closeLogin");
    };
    Login.prototype.resetPassModeOn = function (e) {
        if (e === void 0) { e = null; }
        if (e !== null) {
            e.preventDefault();
        }
        this.loginTitle = this.$t("Password Recovery").toString();
        this.loginButtonTxt = this.$t("Send").toString();
        this.resetPasswordMode = true;
        this.loginMode = false;
        this.registerMode = false;
    };
    Login.prototype.toogleModeOn = function () {
        if (this.loginMode) {
            this.registerModeOn();
            return;
        }
        this.loginModeOn();
    };
    Login.prototype.loginModeOn = function () {
        var dom = document.getElementById("fashionCtrl");
        if (dom !== null) {
            dom.classList.remove("active");
        }
        this.loginTitle = this.$t("Login").toString();
        this.loginButtonTxt = this.$t("Send").toString();
        this.resetPasswordMode = false;
        this.loginMode = true;
        this.registerMode = false;
    };
    Login.prototype.registerModeOn = function () {
        var dom = document.getElementById("fashionCtrl");
        if (dom !== null) {
            dom.classList.add("active");
        }
        this.loginTitle = this.$t("Register").toString();
        this.loginButtonTxt = this.$t("Send").toString();
        this.resetPasswordMode = false;
        this.loginMode = false;
        this.registerMode = true;
    };
    Login.prototype.buttonIsVisible = function () {
        //Hide when register request is sent
        if (this.loginMode) {
            return true;
        }
        return !this.registerOk;
    };
    Login.prototype.loginIsOpenWatch = function () {
        var loginModeOn = LocalStorage.get("loginModeOn");
        if (loginModeOn === null || loginModeOn === undefined) {
            return;
        }
        LocalStorage.delete("loginModeOn");
        this.loginMode = (loginModeOn === 'true');
        this.toogleModeOn();
    };
    __decorate([
        Prop({ default: false })
    ], Login.prototype, "open", void 0);
    __decorate([
        Prop({ default: false })
    ], Login.prototype, "registerOption", void 0);
    __decorate([
        Prop()
    ], Login.prototype, "returnUrl", void 0);
    __decorate([
        Watch("$store.state.loginIsOpen")
    ], Login.prototype, "loginIsOpenWatch", null);
    Login = __decorate([
        Component({
            components: { Popup: Popup, InputPassword: InputPassword, Checkbox: Checkbox },
        })
    ], Login);
    return Login;
}(Vue));
export default Login;
