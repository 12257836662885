import { render, staticRenderFns } from "./menuUpper.vue?vue&type=template&id=1776f3ca&scoped=true&"
import script from "./menuUpper.vue?vue&type=script&lang=ts&"
export * from "./menuUpper.vue?vue&type=script&lang=ts&"
import style0 from "./menuUpper.vue?vue&type=style&index=0&id=1776f3ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1776f3ca",
  null
  
)

export default component.exports