import Vue from '../../../shared/node_modules/vue';
import VueRouter from '../../../shared/node_modules/vue-router';
import store from '../../../shared/core/store';
import { LocalStorage } from '../../../shared/core/localStorage';
Vue.use(VueRouter);

/************************************************************
 meta options:
 public default false
 layout [secure | insecure ] default insecure
/************************************************************/
const routes = [

    { path: '/', name: "index", meta: { public: true }, component: require('../pages/index.vue').default, props: true },
    { path: '/changePass', name: "changepass", meta: { public: false }, component: require('../../../shared/pages/changePasswordPage.vue').default, props: true },
    { path: '/shop', name: "shop", meta: { public: true }, component: require('../../../shared/pages/shopPage.vue').default, props: true },
    { path: '/shop/:id', name: "shop-product", meta: { public: true }, component: require('../../../shared/pages/productPage.vue').default, props: true },
    { path: '/blog', name: "blog", meta: { public: true }, component: require('../../../shared/pages/blogPage.vue').default, props: true },
    { path: '/post/:id', name: "post", meta: { public: true }, component: require('../../../shared/pages/postPage.vue').default, props: true },
    { path: '/checkout', name: "checkout", meta: { public: true }, component: require('../../../shared/pages/checkoutPage.vue').default, props: true },
    { path: '/debts', name: "debts", meta: { public: false }, component: require('../../../shared/pages/debtsPage.vue').default, props: true },
    { path: '/cz/ok/', name: "payment-ok", meta: { public: true }, component: require('../../../shared/pages/paymentOkPage.vue').default, props: true },
    { path: '/czko/', name: "payment-ko", meta: { public: true }, component: require('../../../shared/pages/paymentKoPage.vue').default, props: true },
    { path: '/customer/reset-password/:token', name: "reset-password", meta: { public: true }, component: require('../../../shared/pages/resetPasswordPage.vue').default, props: true },
    { path: '/customer/activate/:token', name: "activate", meta: { public: true }, component: require('../../../shared/pages/activatePage.vue').default, props: true },
    { path: '/passReset/ok/', name: "pass-reset-ok", meta: { public: true }, component: require('../../../shared/pages/passResetOkPage.vue').default, props: true },
    { path: '/privacy', name: "privacy", meta: { public: true }, component: require('../../../shared/pages/privacyPage.vue').default, props: true },
    { path: '/legal', name: "legal", meta: { public: true }, component: require('../../../shared/pages/legalPage.vue').default, props: true },
    { path: '/cookies', name: "cookies", meta: { public: true }, component: require('../../../shared/pages/cookiesPage.vue').default, props: true },
    { path: '/generalConditions', name: "general-conditions", meta: { public: true }, component: require('../../../shared/pages/generalConditionsPage.vue').default, props: true },

    //bookings (delete)
    //{ path: '/bookings-menu', name: "bookings-menu", meta: { public: true }, component: require('../../../shared/bookings/bookingsMenuPage.vue').default, props: true },
    { path: '/bookings-byplace/:bookingTypeId?', name: "bookings-byplace", meta: { public: true }, component: require('../../../shared/bookings/bookingsByPlacePage.vue').default, props: true },
    { path: '/booking-visual/:bookingId/:templateId', name: "booking-visual", meta: { public: false }, component: require('../../../shared/bookings/bookingVisualPage.vue').default, props: true },
    { path: '/customer-bookings', name: "customer-bookings", meta: { public: false }, component: require('../../../shared/bookings/myBookingsPage.vue').default, props: true },
    { path: '/customer-orders', name: "customer-orders", meta: { public: false }, component: require('../../../shared/pages/myOrdersPage.vue').default, props: true },
    { path: '/customer-fees', name: "customer-fees", meta: { public: false }, component: require('../../../shared/pages/myFeesPage.vue').default, props: true },
    { path: '/customer-orders/:id', name: "customer-order", meta: { public: false }, component: require('../../../shared/pages/myOrderPage.vue').default, props: true },
    { path: '/bookings/:bookingTypeId?', name: "bookings", meta: { public: true }, component: require('../../../shared/bookings/bookingsPage.vue').default, props: true },
    { path: '/bookingtypes', name: "bookingtypes", meta: { public: true }, component: require('../../../shared/bookings/bookingTypesPage.vue').default, props: true },
    { path: '/customer-vouchers', name: "customer-vouchers", meta: { public: false }, component: require('../../../shared/pages/myVouchersPage.vue').default, props: true },


    // overrides
    { path: '/account', name: "account", meta: { public: false }, component: require('../pages/accountPage.vue').default, props: true },

    //soccer
    { path: '/calendar', name: "calendar", meta: { public: false }, component: require('../pages/calendarPage.vue').default, props: true },
    { path: '/trainings', name: "trainings", meta: { public: false }, component: require('../pages/trainingsPage.vue').default, props: true },
    { path: '/trainings/:id', name: "training", meta: { public: false }, component: require('../pages/trainingPage.vue').default, props: true },
    { path: '/tournaments', name: "tournaments", meta: { public: false }, component: require('../pages/tournamentsPage.vue').default, props: true },
    { path: '/matches/:id', name: "match", meta: { public: false }, component: require('../pages/matchPage.vue').default, props: true },


];



export const router = new VueRouter({ mode: 'history', routes: routes });

router.beforeEach(async (to, from, next) => {
    const loading = from.name === '' || from.name === null;
    if (to.path != from.path) {
        Vue.prototype.$prevRoute = from;
    }

    Vue.prototype.$last = from.fullPath;

    if (to.hash.indexOf("#/cz/ok") !== -1) {
        return next({ path: '/cz/ok' });
    }
    if (to.hash.indexOf("#/cz/ko") !== -1) {
        return next({ path: '/cz/ko' });
    }

    if (to.meta?.public !== true) {
        const t = LocalStorage.getWithTimeout("ctk");
        const isLogged = t !== undefined && t !== null && t !== '';
        if (isLogged) {
            return next();
        }

        if (store.state.loginIsOpen !== true) {
            store.commit('openLogin', to.fullPath);
        }

        if (loading) {
            return next({ path: '/' });
        }

        return next(from.fullPath);
    }

    return next();
    //if (to.path == '/admin' && to.query.t) {
    // var callback = (res: boolean): void => {
    //     if (!res) {
    //         return next({
    //             path: '/admin/login',
    //             query: { returnUrl: to.path }
    //         });
    //     } else {
    //         localStorage.setItem(window.location.host + "_sut", to.query.t as string);
    //         router.replace("admin");
    //         return next();
    //     }
    // };
    // checkAutoLogin(to.query.t as string, callback);
    // } else {
    //     if (to.meta.public === true || localStorage.getItem(window.location.host + "_sut")) {
    //         return next();
    //     }
    //     const loggedIn = localStorage.getItem(window.location.host + "_ut");
    //     if (loggedIn == "undefined" || typeof loggedIn === "undefined" || loggedIn == null) {

    //         return next({
    //             path: !to.meta.issuperadmin ? '/admin/login' : "/superadmin/login",
    //             query: { returnUrl: to.path }
    //         });
    //     }
    //     return next();
    // }
});

// async function checkAutoLogin(token: string, callback: Function) { // todo: to helper file
//     const requestOptions: any = {
//         method: "POST",
//         headers: {
//             Accept: "application/json",
//             "Content-Type": " application/x-www-form-urlencoded",
//             'Authorization': 'Bearer ' + token
//         },
//     };

//     await fetch(`${Vue.prototype.$hostname}/api/superadmin/check-auto-auth`, requestOptions)
//         .then(response => {
//             if (!response.ok) {
//                 callback(false);
//             }
//             const contentType = response.headers.get("content-type");
//             if (contentType && contentType.includes("application/json")) {
//                 return response.json();
//             }
//             throw new TypeError("Oops, we haven't got JSON!");
//         })
//         .then(data => callback(data))
//         .catch(e => {
//             console.log(e);
//             callback(false);
//         });

// }