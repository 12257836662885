






















































































































































































































































































































































































































































import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import InputText from "../../../shared/components/form/inputText.vue";
import InputSelect from "../../../shared/components/form/inputSelect.vue";
import InputDate from "../../../shared/components/form/inputDate.vue";
import Checkbox from "../../../shared/components/form/checkBox.vue";
import Popup from "../../../shared/components/popup/popup.vue";
import { LocalStorage } from "../../../shared/core/localStorage";

@Component({
  components: {
    InputText,
    InputDate,
    InputSelect,
    Checkbox,
    Popup,
  },
})
export default class AccountPage extends Vue {
  customer: any = null;
  error: string = "";
  errorDeleteCard: string = "";
  ok: string = "";
  errors: any = {};
  creditCards: any[] | null = null;
  showPopUpLegal: boolean = false;
  showPopUpGeneral: boolean = false;
  showPopUpPrivacy: boolean = false;
  showPopUpCookies: boolean = false;

  privacyPolicyFull: string = "";
  cookiesPolicy: string = "";
  generalConditions: string = "";

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    let url = `${Settings.HostName}/api/publicacademycustomers/${cid}`;
    let data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error("Internal server error getting customer " + cid);
      return;
    }
    this.customer = data;

    await this.getCreditCardData(cid);
    this.getSettings();
  }

  async getCreditCardData(cid:any) {
    let url = `${Settings.HostName}/api/publicpaymentmethods/token-list/${cid}`;
    let data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error(
        "Internal server error getting public payment methods " + cid
      );
      return;
    }
    this.creditCards = data;
  }

  async getSettings() {
    let url = `${Settings.HostName}/api/czconfigs/legal-settings`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Internal server error getting cz legal settings");
      return;
    }

    this.privacyPolicyFull = data.data.privacyPolicyFull;
    this.cookiesPolicy = data.data.cookiesPolicy;
    this.generalConditions = data.data.generalConditions;
  }

  cleanErrors() {
    this.errors.accountfirstname = "";
    this.errors.accountlastname = "";
    this.errors.accountemail1 = "";
    this.errors.accountphone1 = "";
    this.error = "";
    this.errorDeleteCard = "";
  }

  async save(e: any) {
    e.preventDefault();
    const msg = this.$t("Field required").toString();
    this.cleanErrors();

    if (this.customer.name === "") {
      this.errors.accountfirstname = msg;
      this.error = msg;
      return;
    }

    if (this.customer.surname === "") {
      this.errors["accountlastname"] = msg;
      this.error = msg;
      return;
    }

    if (this.customer.email === "") {
      this.errors["accountemail1"] = msg;
      this.error = msg;
      return;
    }

    if (this.customer.phone === "") {
      this.errors["accountphone1"] = msg;
      this.error = msg;
      return;
    }

    this.customer.birthDate = (document.getElementById(
      "account-birthDate"
    ) as HTMLInputElement).value;
    this.customer.gender = (document.getElementById(
      "account-gender"
    ) as HTMLInputElement).value;

    this.ok = "";
    this.error = "";
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    let url = `${Settings.HostName}/api/publicacademycustomers/put-json`;

    var customFieldValues: any[] = [];

    for (var i = 0; i < this.customer.customFields.length; i++) {
      var customFieldValue: any = {
        identity: this.customer.id,
        id: this.customer.customFields[i].id,
        customFieldId: this.customer.customFields[i].customFieldId,
        value: this.customer.customFields[i].value,
      };
      customFieldValues.push(customFieldValue);
    }
    this.customer.customFieldValues = customFieldValues;

    let data = await new CrudService().httpPut(url, this.customer);

    if (data == null || data === undefined) {
      console.error("Internal server error saving customer " + cid);
      return;
    }

    if (
      data.ok === false &&
      data.errorMsg !== "" &&
      data.errorMsg !== undefined &&
      data.errorMsg != null
    ) {
      this.error = data.errorMsg;
      return;
    }

    this.ok = this.$t("Data modified!").toString();
  }

  async deleteCard(e: any, cardId:any) {
    if (cardId == undefined || cardId == null) return;
    e.preventDefault();
    this.cleanErrors();

    this.ok = "";
    this.errorDeleteCard = "";
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
      return;
    }

    let url = `${Settings.HostName}/api/publicpaymentmethods/${cid}/${cardId}`;
    var res = await new CrudService().httpDelete(url, null);
    if (res === null || res === undefined) {
      this.errorDeleteCard = this.$t("Internal server error").toString();
      return;
    }

    if (!res.ok) {
      this.errorDeleteCard = res.errorMsg;
      return;
    }

    await this.getCreditCardData(cid);

    this.ok = this.$t("Data modified!").toString();
  }
}
