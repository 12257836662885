import { render, staticRenderFns } from "./tournamentsPage.vue?vue&type=template&id=d8f8cb60&scoped=true&"
import script from "./tournamentsPage.vue?vue&type=script&lang=ts&"
export * from "./tournamentsPage.vue?vue&type=script&lang=ts&"
import style0 from "./tournamentsPage.vue?vue&type=style&index=0&id=d8f8cb60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8f8cb60",
  null
  
)

export default component.exports