import { render, staticRenderFns } from "./debtsPage.vue?vue&type=template&id=7612865b&scoped=true&"
import script from "./debtsPage.vue?vue&type=script&lang=ts&"
export * from "./debtsPage.vue?vue&type=script&lang=ts&"
import style0 from "./debtsPage.vue?vue&type=style&index=0&id=7612865b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7612865b",
  null
  
)

export default component.exports