var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import { TextBlockType, SortTypeBlog } from "../core/constants";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
var BlogPage = /** @class */ (function (_super) {
    __extends(BlogPage, _super);
    function BlogPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.posts = [];
        _this.error = "";
        _this.sort = [];
        _this.search = "";
        _this.sortType = SortTypeBlog.ByNew;
        _this.firstRecord = 0;
        _this.lastRecord = 0;
        _this.pageSize = 30;
        _this.totalCount = 0;
        _this.paginationClass = "page-item page-item-control";
        _this.paginationClassDisabled = "page-item page-item-control disabled";
        _this.numPages = 4;
        return _this;
    }
    BlogPage.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.lastRecord = this.pageSize;
                        this.sort = [
                            { value: 1, label: this.$t("Sort By New") },
                            { value: 2, label: this.$t("Sort By Old") },
                        ];
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BlogPage.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filters, postTypeFilter, searchFilter, sort, gridStatus, url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filters = {};
                        postTypeFilter = {};
                        searchFilter = {};
                        sort = [];
                        if (this.sortType !== null) {
                            switch (Number(this.sortType)) {
                                case SortTypeBlog.ByNew:
                                    sort = [{ colId: "Created", sort: "desc" }];
                                    break;
                                case SortTypeBlog.ByOld:
                                    sort = [{ colId: "Created", sort: "asc" }];
                                    break;
                            }
                        }
                        postTypeFilter["TextBlockType"] = {
                            type: "equals",
                            filter: TextBlockType.Posts,
                            filterType: "number",
                        };
                        if (this.search !== null && this.search !== "") {
                            searchFilter["Title"] = {
                                condition1: {
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                condition2: {
                                    secondField: "Text",
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                condition3: {
                                    secondField: "SmalText",
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                operator: "OR",
                            };
                            filters = Object.assign(filters, searchFilter);
                        }
                        filters = Object.assign(filters, postTypeFilter);
                        gridStatus = JSON.stringify({ filters: filters, sort: sort });
                        gridStatus = encodeURIComponent(gridStatus);
                        url = Settings.HostName + "/api/cztextblocks/list-by-page?startRow=" + this.firstRecord + "&endRow=" + this.lastRecord + "&gridStatus=" + gridStatus;
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _a.sent();
                        if (data == null || data === undefined || data.ok === false) {
                            console.error("Internal server error getting posts");
                            return [2 /*return*/];
                        }
                        this.posts = data.data;
                        this.totalCount = data.totalCount;
                        if (this.lastRecord >= this.totalCount) {
                            this.lastRecord = this.totalCount;
                        }
                        window.scrollTo(0, 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    BlogPage.prototype.getImage = function (name) {
        if (name !== undefined && name !== "" && name !== null) {
            return store.state.settings.Uploads + "/" + name;
        }
        return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    };
    BlogPage.prototype.getDateFormatted = function (dt) {
        return DateHelper.formatDate(new Date(dt));
    };
    BlogPage.prototype.getText = function (text) {
        if (text === undefined || text === null || text.length < 100) {
            return text;
        }
        return text.substring(0, 100);
    };
    BlogPage.prototype.getTextMonth = function (date) {
        var created = DateHelper.toSystemDate(date);
        var month = created.getMonth();
        if (month !== undefined) {
            return DateHelper.getTextMonth(this, month);
        }
    };
    BlogPage.prototype.getDay = function (date) {
        var created = DateHelper.toSystemDate(date);
        return created.getDate();
    };
    BlogPage.prototype.getpaginationClassPrev = function () {
        return this.firstRecord <= 0
            ? this.paginationClassDisabled
            : this.paginationClass;
    };
    BlogPage.prototype.getpaginationClassNext = function () {
        return this.lastRecord >= this.totalCount
            ? this.paginationClassDisabled
            : this.paginationClass;
    };
    BlogPage.prototype.getItemListClass = function (index) {
        return index == 1
            ? "page-item pointer li-margin active"
            : "page-item pointer li-margin";
    };
    BlogPage.prototype.nextPage = function () {
        this.firstRecord += this.pageSize;
        this.lastRecord =
            this.firstRecord + this.pageSize >= this.totalCount
                ? this.totalCount
                : this.firstRecord + this.pageSize;
        this.loadData();
    };
    BlogPage.prototype.navigateTo = function (index) {
        var page = this.firstRecord / this.pageSize + (index - 1);
        this.firstRecord = page * this.pageSize;
        this.lastRecord =
            this.firstRecord + this.pageSize >= this.totalCount
                ? this.totalCount
                : this.firstRecord + this.pageSize;
        this.loadData();
    };
    BlogPage.prototype.previousPage = function () {
        this.firstRecord -= this.pageSize;
        this.lastRecord = this.firstRecord + this.pageSize;
        this.loadData();
    };
    BlogPage.prototype.selectSort = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.firstRecord = 0;
                        this.lastRecord = this.pageSize;
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BlogPage.prototype.selectSearch = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.search === "" || this.search.length > 3)) return [3 /*break*/, 2];
                        this.firstRecord = 0;
                        this.lastRecord = this.pageSize;
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BlogPage = __decorate([
        Component({
            components: {},
        })
    ], BlogPage);
    return BlogPage;
}(Vue));
export default BlogPage;
