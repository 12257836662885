var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from "vue-property-decorator";
import Popup from "../../components/popup/popup.vue";
import { Format } from "../../core/helpers/formatHelper";
var CancelReturnsInfoCz = /** @class */ (function (_super) {
    __extends(CancelReturnsInfoCz, _super);
    function CancelReturnsInfoCz() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showModal = false;
        return _this;
    }
    CancelReturnsInfoCz.prototype.mounted = function () {
        this.showModal =
            this.infoReturns !== undefined &&
                this.infoReturns !== null &&
                this.infoReturns.length > 0;
    };
    CancelReturnsInfoCz.prototype.formatAmount = function (amount) {
        if (amount == null) {
            return Format.formatCurrency("0");
        }
        return Format.formatCurrency(amount);
    };
    __decorate([
        Prop()
    ], CancelReturnsInfoCz.prototype, "infoReturns", void 0);
    CancelReturnsInfoCz = __decorate([
        Component({
            components: { Popup: Popup },
        })
    ], CancelReturnsInfoCz);
    return CancelReturnsInfoCz;
}(Vue));
export default CancelReturnsInfoCz;
