var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import TimeTable from "../../../shared/components/timetable/timetable.vue";
Component.registerHooks(["beforeRouteLeave"]);
var CalendarPage = /** @class */ (function (_super) {
    __extends(CalendarPage, _super);
    function CalendarPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.timeTableMatchKey = 0;
        _this.highLightField = "";
        _this.highLightValues = [];
        _this.timeOutId = 0;
        return _this;
    }
    CalendarPage.prototype.beforeRouteLeave = function (to, from, next) {
        // beforeRouteLeave only works in root components
        if (this.timeOutId !== 0 &&
            this.timeOutId !== undefined &&
            this.timeOutId !== null) {
            clearInterval(this.timeOutId);
            this.timeOutId = null;
        }
        return next();
    };
    CalendarPage = __decorate([
        Component({
            components: {
                TimeTable: TimeTable,
            },
        })
    ], CalendarPage);
    return CalendarPage;
}(Vue));
export default CalendarPage;
