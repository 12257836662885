var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import { LocalStorage } from "../core/localStorage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { DateHelper } from "../core/helpers/dateHelper";
import { Format } from "../core/helpers/formatHelper";
import Popup from "../components/popup/popup.vue";
import BookingsFilters from "./bookingsFilters.vue";
import DatePicker from "../components/datePicker/datePicker.vue";
import { ShopService } from "../core/services/shopService";
import { DatePickerMode } from "../core/constants";
import store from "../core/store";
import { Loader } from "../core/helpers/loaderHelper";
var BookingsPage = /** @class */ (function (_super) {
    __extends(BookingsPage, _super);
    function BookingsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMsg = "";
        _this.data = [];
        _this.dbData = [];
        _this.date = "";
        _this.bookingTypeSelected = null;
        _this.resourceTypeId = null;
        _this.showPopUpConfirm = false;
        _this.bookingSelected = {};
        _this.searchKey = "";
        _this.closeFilter = 0;
        _this.openFilter = 0;
        _this.filterButton = false;
        _this.dbBookingTypes = [];
        _this.resourceTypes = [];
        _this.bookingTypes = [];
        _this.durations = [];
        _this.showOtherResources = false;
        _this.otherResourceTypes = null;
        _this.otherResourceOptionIndex = 0;
        _this.blocks = [];
        _this.carouselSettings = null;
        return _this;
    }
    BookingsPage.prototype.loadCarrouselSettings = function () {
        this.carouselSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 8,
            centerMode: this.dbData.length <= 8 && this.dbData.length != 0 ? true : false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
    };
    BookingsPage.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = Settings.HostName + "/api/publicbookings/filters";
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        res = _a.sent();
                        if (res == null || res.ok === false) {
                            console.error("Internal server error getting filters");
                            this.errorMsg = this.$t("Internal server error. Please try again later").toString();
                            return [2 /*return*/];
                        }
                        this.dbBookingTypes = res.model.bookingTypes;
                        this.bookingTypes = res.model.bookingTypes;
                        this.resourceTypes = res.model.resourceTypes;
                        this.setUpBookingTypeFilter();
                        //this.doNotHideFilter();
                        return [4 /*yield*/, this.loadData()];
                    case 2:
                        //this.doNotHideFilter();
                        _a.sent();
                        document.addEventListener("click", this.doNotHideFilter);
                        return [2 /*return*/];
                }
            });
        });
    };
    /// the controls hides on global click
    BookingsPage.prototype.doNotHideFilter = function () {
        var domFilterClose = document.getElementById("dom-filter-close");
        domFilterClose === null || domFilterClose === void 0 ? void 0 : domFilterClose.classList.add("active");
    };
    BookingsPage.prototype.closeFilters = function () {
        this.closeFilter++; // to trigger watch
    };
    BookingsPage.prototype.openFilters = function () {
        this.openFilter++; // to trigger watch
    };
    BookingsPage.prototype.setUpBookingTypeFilter = function () {
        if (this.bookingTypes.length === 1) {
            var bType = this.dbBookingTypes[0];
            this.bookingTypeSelected = bType;
            return;
        }
        this.filterButton = true;
        if (this.$route.params.bookingTypeId !== undefined) {
            var bookingTypeId_1 = Number(this.$route.params.bookingTypeId);
            this.bookingTypeSelected = this.dbBookingTypes.find(function (y) { return y.id == bookingTypeId_1; });
            return;
        }
        setTimeout(this.openFilters, 100);
    };
    BookingsPage.prototype.selectBookingType = function (bType) {
        if (bType.byPlace === true) {
            return this.$router.push("/bookings-byplace/" + bType.id);
        }
        if (this.bookingTypeSelected === bType.id) {
            this.bookingTypeSelected = null;
            return;
        }
        this.bookingTypeSelected = bType;
        if (this.bookingTypeSelected !== null) {
            this.loadData();
        }
    };
    BookingsPage.prototype.resourceTypeSelected = function (id) {
        if (this.resourceTypeId === id) {
            this.resourceTypeId = null;
            return;
        }
        this.resourceTypeId = id;
        if (this.bookingTypeSelected !== null) {
            this.loadData();
        }
    };
    BookingsPage.prototype.searchBookingType = function () {
        var _this = this;
        if (this.searchKey === "") {
            this.bookingTypes = this.dbBookingTypes;
        }
        var res = this.bookingTypes.filter(function (y) { return y.name.toLowerCase().indexOf(_this.searchKey.toLowerCase()) !== -1; });
        this.bookingTypes = res;
    };
    BookingsPage.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, url, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMsg = "";
                        if (this.date == null ||
                            this.date === undefined ||
                            this.dbBookingTypes.length === 0) {
                            return [2 /*return*/];
                        }
                        if (this.bookingTypeSelected === null ||
                            this.bookingTypeSelected === undefined) {
                            return [2 /*return*/];
                        }
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        this.data = [];
                        url = Settings.HostName + "/api/publicbookings/list";
                        Loader.Loading("loader");
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                date: this.date == "" ? new Date() : this.date,
                                bookingTypeId: this.bookingTypeSelected.id,
                                customerId: cid === 0 ? null : cid,
                                resourceTypeId: this.resourceTypeId,
                            })];
                    case 1:
                        res = _a.sent();
                        Loader.Unloading("loader");
                        if (res == null) {
                            console.error("Internal server error getting bookings");
                            return [2 /*return*/];
                        }
                        if (res.ok === false &&
                            res.errorMsg !== undefined &&
                            res.errorMsg !== null) {
                            this.errorMsg = res.errorMsg;
                            return [2 /*return*/];
                        }
                        if (res.model === null || res.model.list.length === 0) {
                            this.errorMsg = this.$t("Sorry nothing found, try with other filters or dates").toString();
                            this.data.length = 0;
                            return [2 /*return*/];
                        }
                        this.data = res.model.list;
                        this.otherResourceTypes = res.model.multipleResources;
                        this.dbData = res.model.list;
                        this.setUpDurations();
                        this.loadCarrouselSettings();
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsPage.prototype.setUpDurations = function () {
        this.durations = [];
        for (var _i = 0, _a = this.data; _i < _a.length; _i++) {
            var resource = _a[_i];
            for (var _b = 0, _c = resource.timeTable; _b < _c.length; _b++) {
                var timetable = _c[_b];
                var _loop_1 = function (duration) {
                    if (this_1.durations.find(function (y) { return y === duration; }) === undefined) {
                        this_1.durations.push(duration);
                    }
                };
                var this_1 = this;
                for (var _d = 0, _e = timetable.durations; _d < _e.length; _d++) {
                    var duration = _e[_d];
                    _loop_1(duration);
                }
            }
        }
        if (this.durations.length === 1) {
            this.durations = [];
        }
    };
    BookingsPage.prototype.selectDuration = function (e, duration, resource, start, noOnlineBlocks) {
        if (noOnlineBlocks === void 0) { noOnlineBlocks = null; }
        return __awaiter(this, void 0, void 0, function () {
            var _i, noOnlineBlocks_1, noOnlineBlock, othersLength;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.blocks.push({
                            duration: duration,
                            resourceId: resource.id,
                            resourceName: resource.name,
                            startDate: start,
                            sstart: DateHelper.toCultureString(start),
                            noOnline: false,
                        });
                        if (noOnlineBlocks !== null) {
                            for (_i = 0, noOnlineBlocks_1 = noOnlineBlocks; _i < noOnlineBlocks_1.length; _i++) {
                                noOnlineBlock = noOnlineBlocks_1[_i];
                                this.blocks.push({
                                    duration: noOnlineBlock.duration,
                                    resourceId: noOnlineBlock.resourceId,
                                    resourceName: noOnlineBlock.resourceName,
                                    startDate: noOnlineBlock.start,
                                    sstart: DateHelper.toCultureString(noOnlineBlock.start),
                                    noOnline: true,
                                });
                            }
                        }
                        this.errorMsg = "";
                        this.closeFilters();
                        othersLength = this.showOtherResources !== undefined && this.otherResourceTypes !== null
                            ? this.otherResourceTypes.length
                            : 0;
                        if (!(othersLength != 0 && this.otherResourceOptionIndex < othersLength)) return [3 /*break*/, 2];
                        // show popup with other related resources options
                        return [4 /*yield*/, this.loadRelatedResourceOptions(this.blocks[0])];
                    case 1:
                        // show popup with other related resources options
                        _a.sent();
                        this.showOtherResources = true;
                        return [2 /*return*/];
                    case 2:
                        this.showOtherResources = false;
                        this.showPopUpConfirm = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsPage.prototype.loadRelatedResourceOptions = function (block) {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, id, url, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.otherResourceTypes === null) {
                            return [2 /*return*/];
                        }
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        id = this.otherResourceTypes[this.otherResourceOptionIndex];
                        url = Settings.HostName + "/api/publicbookings/date-availability";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                date: block.startDate,
                                customerId: cid === 0 ? null : cid,
                                bookingTypeResourceId: id,
                            })];
                    case 1:
                        res = _a.sent();
                        if (res == null) {
                            console.error("Internal server error getting date-availability");
                            return [2 /*return*/];
                        }
                        if (res.ok === false || res.model === null || res.model.length === 0) {
                            this.errorMsg = this.$t("Sorry nothing found. Try with other filters").toString();
                            return [2 /*return*/];
                        }
                        this.otherResourceOptionIndex += 1;
                        this.dataByResource = res.model;
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsPage.prototype.addToCart = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, bookingTypeId, bookingTypeName, booking, i, block, url, res, sale;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMsg = "";
                        e.preventDefault();
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        if (cid === 0 || cid === null) {
                            this.errorMsg = this.$t("Please login or register").toString();
                            return [2 /*return*/];
                        }
                        if (this.blocks.length === 0) {
                            return [2 /*return*/];
                        }
                        bookingTypeId = this.bookingTypeSelected.id;
                        bookingTypeName = this.bookingTypeSelected.name;
                        booking = {};
                        booking.customerId = cid;
                        booking.bookingTypeId = bookingTypeId;
                        booking.bookingTypeName = bookingTypeName;
                        booking.isValidated = false;
                        booking.sendCommunications = false;
                        booking.origin = 0; // todo to enum
                        if (booking.customerId !== null) {
                            for (i = 0; i < this.blocks.length; i++) {
                                block = this.blocks[i];
                                block.customerId = booking.customerId;
                            }
                        }
                        Loader.Loading("addToCartBtn");
                        url = Settings.HostName + "/api/publicbookings/prebook";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                booking: booking,
                                customerIsParticipant: true,
                                blocks: this.blocks,
                                isMultiple: false,
                            })];
                    case 1:
                        res = _a.sent();
                        Loader.Unloading("addToCartBtn");
                        if (res === undefined) {
                            // login
                            this.showPopUpConfirm = false;
                            this.errorMsg = "";
                            return [2 /*return*/];
                        }
                        if (res == null) {
                            console.error("Internal server error pre booking ");
                            this.errorMsg = this.$t("Internal server error").toString();
                            return [2 /*return*/];
                        }
                        if (res.ok === false) {
                            if (res.model !== undefined && res.model !== null) {
                                this.errorMsg = Object.values(res.model).join(", ");
                                return [2 /*return*/];
                            }
                            this.errorMsg = res.errorMsg;
                            return [2 /*return*/];
                        }
                        if (res.model.serviceFree === true) {
                            return [2 /*return*/, this.$router.push("/customer-bookings")];
                        }
                        this.closeFilters();
                        sale = res.model;
                        sale.title = sale.description;
                        sale.cancelUrl = "publicbookings/cancel/" + sale.bookingId + "/" + cid;
                        new ShopService().addSaleToCart(sale, e.currentTarget);
                        this.$router.push("/checkout");
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsPage.prototype.filterByDate = function (date) {
        this.date = date.systemStrDate;
        this.loadData();
    };
    BookingsPage.prototype.filterByDuration = function (duration) {
        this.data = this.dbData;
        for (var _i = 0, _a = this.data; _i < _a.length; _i++) {
            var resource = _a[_i];
            var _loop_2 = function (timetable) {
                if (timetable.durations.find(function (y) { return y === duration; }) === undefined) {
                    resource.timeTable = resource.timeTable.filter(function (y) { return y !== timetable; });
                }
            };
            for (var _b = 0, _c = resource.timeTable; _b < _c.length; _b++) {
                var timetable = _c[_b];
                _loop_2(timetable);
            }
        }
    };
    BookingsPage.prototype.toogle = function (key, index, timetable, resourceId) {
        var collapse = document.getElementById("id_accordion" + key + index + "Collapse1");
        var accordion = document.getElementById("#accordion" + key + index + "Collapse1");
        if (collapse.classList.contains("collapsed")) {
            collapse.setAttribute("aria-expanded", true);
            collapse.classList.remove("collapsed");
            accordion.classList.add("show");
            this.getPrice(key, index, timetable, resourceId);
        }
        else {
            collapse.setAttribute("aria-expanded", false);
            collapse.classList.add("collapsed");
            accordion.classList.remove("show");
        }
    };
    BookingsPage.prototype.getPrice = function (key, index, timetable, resourceId) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, duration, spanPrice, price, cid, sid, url, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = timetable.durations;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        duration = _a[_i];
                        spanPrice = document.getElementById("id_price" + key + index + "_" + duration);
                        price = timetable.priceByDuration[duration];
                        if (price !== null && price !== undefined) {
                            spanPrice.innerHTML = Format.formatCurrency(price);
                            return [3 /*break*/, 3];
                        }
                        Loader.Loading("id_price" + key + index + "_" + duration);
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        url = Settings.HostName + "/api/publicbookings/loadprice";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                startDate: timetable.start,
                                bookingTypeId: this.bookingTypeSelected.id,
                                duration: duration,
                                customerId: cid === 0 ? null : cid,
                                resourceId: resourceId,
                            })];
                    case 2:
                        res = _b.sent();
                        Loader.Unloading("id_price" + key + index + "_" + duration);
                        // todo check res
                        timetable.priceByDuration[duration] = res.price;
                        spanPrice.innerHTML = Format.formatCurrency(res.price);
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BookingsPage.prototype.datePickerMode = function () {
        return DatePickerMode.Date;
    };
    BookingsPage.prototype.getResourceImage = function (image) {
        if (image !== undefined && image !== "" && image !== null) {
            return store.state.settings.Uploads + "/" + image;
        }
        return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    };
    BookingsPage.prototype.goToBottomPage = function (e) {
        this.closeFilters();
        e.preventDefault();
        window.scrollTo(0, document.body.scrollHeight);
    };
    BookingsPage = __decorate([
        Component({
            components: {
                VueSlickCarousel: VueSlickCarousel,
                Popup: Popup,
                DatePicker: DatePicker,
                BookingsFilters: BookingsFilters,
            },
        })
    ], BookingsPage);
    return BookingsPage;
}(Vue));
export default BookingsPage;
