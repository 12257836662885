

























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InputSelect extends Vue {
  @Prop() cssClass!: string;
  @Prop() id!: string;
  @Prop() options!: any; // {value,label}
  @Prop() value!: string;

  mounted() {}
}
