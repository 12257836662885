import store from "../store";
var Format = /** @class */ (function () {
    function Format() {
    }
    Format.toLowerFirst = function (value) {
        return value.charAt(0).toLowerCase() + value.slice(1);
    };
    Format.formatCurrency = function (value) {
        if (value != "0" && (value == "" || value == null || value == undefined))
            return "";
        var formated = this.formatNumber(value);
        return formated + store.state.settings.CurrencySymbol;
    };
    Format.formatNumber = function (value) {
        if (value != "0" && (value == "" || value == null || value == undefined))
            return "";
        if (value.toString().indexOf('.') === -1) {
            return value;
        }
        var culture = store.state.settings.Culture;
        if (culture === "" || culture === null || culture === undefined) {
            culture = "en-US";
        }
        var formatter = new Intl.NumberFormat(culture, {
            minimumFractionDigits: 2
        });
        var s = formatter.format(Number(value));
        return s;
    };
    Format.highLightText = function (words, query) {
        var iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function (matchedTxt, a, b) {
            return ('<span class=\'post-creative-time\'>' + matchedTxt + '</span>');
        });
    };
    Format.IsNull = function (value) {
        return value === "" || value === null || value === undefined;
    };
    return Format;
}());
export { Format };
