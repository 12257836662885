var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalStorage } from "../localStorage";
import store from "../store";
var CrudService = /** @class */ (function () {
    function CrudService() {
    }
    CrudService.prototype.httpGet = function (url, body, callback, contentType) {
        if (contentType === void 0) { contentType = "json"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpFetch(url, "GET", body, callback, contentType)];
            });
        });
    };
    CrudService.prototype.httpPost = function (url, body, callback, contentType) {
        if (contentType === void 0) { contentType = "json"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpFetch(url, "POST", body, callback, contentType)];
            });
        });
    };
    CrudService.prototype.httpPut = function (url, body, callback, contentType) {
        if (contentType === void 0) { contentType = "json"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpFetch(url, "PUT", body, callback, contentType)];
            });
        });
    };
    CrudService.prototype.httpDelete = function (url, body, callback, contentType) {
        if (contentType === void 0) { contentType = "json"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpFetch(url, "DELETE", body, callback, contentType)];
            });
        });
    };
    CrudService.prototype.httpFetch = function (url, method, body, callback, contentType) {
        if (contentType === void 0) { contentType = "json"; }
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        requestOptions = this.buildRequest(method, contentType, body);
                        return [4 /*yield*/, fetch(url, requestOptions)];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            return [2 /*return*/, this.handleBadRequest(response, callback)];
                        }
                        return [4 /*yield*/, this.handleResponse(response, callback)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CrudService.prototype.buildRequest = function (method, contentType, body) {
        var token = LocalStorage.getWithTimeout("ctk");
        var version = localStorage.getItem(window.location.host + "_czv");
        var requestOptions = {
            method: method,
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': this.getContentType(contentType),
                'Authorization': 'Bearer ' + token,
                'CzVersion': version !== null ? version : ''
            }),
            useCredentails: true,
            body: this.buildBody(contentType, body)
        };
        if (contentType != "multipart") {
            var headers = requestOptions.headers;
            headers.append('Content-Type', this.getContentType(contentType));
        }
        return requestOptions;
    };
    CrudService.prototype.handleResponse = function (response, callback) {
        var data;
        this.checkVersion(response);
        var responseCType = response.headers.get("content-type");
        if (responseCType && (responseCType.includes("application/json") ||
            responseCType.includes("application/problem+json"))) {
            data = response.json().then(function (json) {
                if (json.errors) {
                    if (callback)
                        return callback(response.ok, json.errors);
                }
                else {
                    if (callback)
                        return callback(response.ok, json);
                }
                data = json;
                return data;
            }).catch(function (e) {
                console.log(e);
                if (callback)
                    return callback(false);
            });
        }
        else {
            console.log("Internal Server Error", response);
            if (callback)
                return callback(false, data);
            return data;
        }
        return data;
    };
    CrudService.prototype.handleBadRequest = function (response, callback) {
        if (response.status === 401 || response.status === 403) {
            if (LocalStorage.getWithTimeout("ctk") != "") {
                LocalStorage.delete("ctk");
                if (callback)
                    return callback(false);
                //location.href = "/";
                store.commit("openLogin", window.location.pathname);
            }
            return;
        }
        return this.handleResponse(response, callback);
    };
    CrudService.prototype.buildBody = function (ct, body) {
        if (!body) {
            return null;
        }
        if (body && ct == "json") {
            return JSON.stringify(body);
        }
        return body;
    };
    CrudService.prototype.getContentType = function (ct) {
        if (ct == "json")
            return "application/json-patch+json";
        if (ct == "form")
            return "application/x-www-form-urlencoded";
        if (ct == "multipart")
            return "multipart/form-data";
        return "json";
    };
    CrudService.prototype.checkVersion = function (response) {
        var versionExpired = response.headers.get("Expired");
        if (versionExpired === null || versionExpired === undefined) {
            return;
        }
        localStorage.setItem(window.location.host + "_czv", versionExpired);
        localStorage.setItem(window.location.host + "_cz_new_version_reload", versionExpired);
        console.log("New version " + versionExpired);
        window.location.reload();
    };
    return CrudService;
}());
export { CrudService };
