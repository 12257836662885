



































































































































































































import { Component, Vue } from "vue-property-decorator";
import { TenantSettings } from "../../core/services/configService";
import VueGallery from "vue-gallery";
import store from "../../core/store";

@Component({
  components: {
    VueGallery,
  },
})
export default class Footer extends Vue {
  settings: TenantSettings = {};
  logo: string = "";
  images: string[] = [];
  index: any = null;

  created() {}
  mounted() {
    this.settings = store.state.settings;
    if (this.settings === undefined) {
      return;
    }
    this.logo = this.settings.Uploads + "/" + this.settings.Mainlogo;

    if (this.settings.Gallery !== null && this.settings.Gallery !== undefined) {
      for (const image of this.settings.Gallery) {
        this.images.push(this.getImagePath(image.img));
      }
    }
  }

  getImagePath(imgName: string) {
    return this.settings.Uploads + "/CzConfig/CzGallery/" + imgName;
  }
}
