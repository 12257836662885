




























































import {
  Component,
  Vue,
} from "../../../shared/node_modules/vue-property-decorator";
import Slider from "../../../shared/components/slider/slider.vue";
import TrendingProducts from "../../../shared/components/trendingProducts/trendingProducts.vue";
import Gallery from "../../../shared/components/gallery/gallery.vue";
import UsInNumbers from "../../../shared/components/usInNumbers/usInNumbers.vue";
import Testimonial from "../../../shared/components/testimonial/testimonial.vue";
import Team from "../../../shared/components/team/team.vue";
import LastPosts from "../../../shared/components/lastPosts/lastPosts.vue";
import DirectAccessMenu from "../../../shared/components/directAccessMenu/directAccessMenu.vue";
import PopularProducts from "../../../shared/components/popularProducts/popularProducts.vue";
import Sponsors from "../../../shared/components/sponsors/sponsors.vue";
import Banners from "../../../shared/components/banners/banners.vue";
import {
  Settings,
  TenantSettings,
} from "../../../shared/core/services/configService";
import { CrudService } from "../../../shared/core/services/crudService";
import store from "../../../shared/core/store";

@Component({
  components: {
    Slider,
    DirectAccessMenu,
    TrendingProducts,
    Gallery,
    UsInNumbers,
    Testimonial,
    Team,
    LastPosts,
    PopularProducts,
    Sponsors,
    Banners
  },
})
export default class IndexPage extends Vue {
  settings: TenantSettings = {};
  directAccesMenuData: any[] = [];
  czTemplateSettings: any;
  trendingProductImg: string = "";
  gallery: any[] = [];
  galleryData: any[] = [];
  galleryPath: string = "";
  usInNumbers: any = null;
  slider: any = null;

  async mounted() {
    this.settings = store.state.settings;
    this.configureDirectAccess();

    const url = `${Settings.HostName}/api/czconfigs/template`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Internal server error getting cz template settings");
      return;
    }

    this.configureTrendingProducts(data.data.mainTrandingProductImage);
    this.cofigureUsInNumbers(data);
    this.slider = data.data;
    await this.loadGallery();
  }

  async loadGallery() {
    const dbGallery = localStorage.getItem(Settings.Prefix + "all-gallery"); // setted in czmenu todo: if no menu wont load
    if (dbGallery !== null) {
      const gallery = JSON.parse(dbGallery);
      this.galleryData = gallery;
      return;
    }
  }

  cofigureUsInNumbers(data: any) {
    if (
      data.data.number1 === null &&
      data.data.number2 === null &&
      data.data.number3 === null &&
      data.data.number4 === null
    ) {
      return;
    }

    this.usInNumbers = {
      number1: data.data.number1,
      number2: data.data.number2,
      number3: data.data.number3,
      number4: data.data.number4,
      textNumbers1: data.data.textNumbers1,
      textNumbers2: data.data.textNumbers2,
      textNumbers3: data.data.textNumbers3,
      textNumbers4: data.data.textNumbers4,
    };
  }

  configureTrendingProducts(img: string) {
    if (img !== undefined) {
      this.trendingProductImg = this.settings.Uploads + "/" + img;
    }
  }

  configureDirectAccess() {
    this.directAccesMenuData.push({
      //img: "/img/calendar.jpg",
      link: "/calendar",
      title: this.$t("Calendar"),
    });
    this.directAccesMenuData.push({
      // img: "/img/training.jpg",
      link: "/trainings",
      title: this.$t("Trainings"),
    });
    this.directAccesMenuData.push({
      //img: "/img/tournament.jpg",
      link: "/tournaments",
      title: this.$t("Tournaments"),
    });
    this.directAccesMenuData.push({
      // img: "/img/account.jpg",
      link: "/account",
      title: this.$t("Account"),
    });
    this.directAccesMenuData.push({
      // img: "/img/notification.jpg",
      link: "#",
      title: this.$t("Notifications"),
    });
    this.directAccesMenuData.push({
      // img: "/img/finance.jpg",
      link: "#",
      title: this.$t("Your Orders"),
    });
  }
}
