var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";
var InputDate = /** @class */ (function (_super) {
    __extends(InputDate, _super);
    function InputDate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.valueFormatted = "";
        return _this;
    }
    InputDate.prototype.mounted = function () {
        var d = DateHelper.toDate(this.value, DateHelper.systemFormat);
        if (d == undefined) {
            console.error("unable to parse date " + this.value);
            return;
        }
        var m = (d.getMonth() + 1).toString();
        if (d.getMonth() + 1 < 10) {
            m = "0" + m;
        }
        var day = d.getDate().toString();
        if (d.getDate() < 10) {
            day = "0" + day;
        }
        this.valueFormatted = d.getFullYear() + "-" + m + "-" + day; // todo: datepicker taking tenant culture format
    };
    __decorate([
        Prop()
    ], InputDate.prototype, "cssClass", void 0);
    __decorate([
        Prop()
    ], InputDate.prototype, "id", void 0);
    __decorate([
        Prop()
    ], InputDate.prototype, "label", void 0);
    __decorate([
        Prop()
    ], InputDate.prototype, "value", void 0);
    InputDate = __decorate([
        Component
    ], InputDate);
    return InputDate;
}(Vue));
export default InputDate;
