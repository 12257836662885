import { render, staticRenderFns } from "./privacyPage.vue?vue&type=template&id=12d90610&"
import script from "./privacyPage.vue?vue&type=script&lang=ts&"
export * from "./privacyPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports