export class Loader {

    static Loading(id: string, dom: any = null) {
        const input = dom !== undefined && dom !== null ? dom : document.getElementById(id);
        if (input === null || input === undefined) {
            return;
        }

        if (input.parentNode === null) {
            return;
        }

        input.classList.remove("d-b");
        input.classList.add("d-n");

        const spinner = document.createElement("div");
        spinner.id = "loader_" + id;
        const div = input.parentElement;
        if (div === null) return;

        div.insertBefore(spinner, div.firstChild);
        spinner.classList.add("spinnerButton");
        spinner.classList.add("background-main-color");
    }

    static Unloading(id: string, dom: any = null) {
        const input = dom !== undefined && dom !== null ? dom : document.getElementById(id);
        if (input === null || input === undefined) {
            return;
        }
        input.classList.remove("d-n");
        input.classList.add("d-b");

        const divLoader = document.getElementById('loader_' + id);
        if (divLoader === null || divLoader === undefined) {
            return;
        }
        divLoader.classList.remove("d-b");
        divLoader.classList.add("d-n");
    }

}