var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Settings, } from "../../../shared/core/services/configService";
import { Component, Vue, Watch, } from "../../../shared/node_modules/vue-property-decorator";
import VueGallery from "../../../shared/node_modules/vue-gallery";
import { CrudService } from "../../../shared/core/services/crudService";
import Login from "../../../shared/components/login/login.vue";
import { LocalStorage } from "../../../shared/core/localStorage";
import store from "../../../shared/core/store";
import MenuShopCart from "../../../shared/components/menuShopCart/menuShopCart.vue";
import MenuSearch from "../../../shared/components/menuSearch/menuSearch.vue";
import MenuUpper from "../../../shared/components/menuUpper/menuUpper.vue";
import "../js/script.js";
import { ShopService } from "../../../shared/core/services/shopService";
var CzMenu = /** @class */ (function (_super) {
    __extends(CzMenu, _super);
    function CzMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.settings = {};
        _this.logoUrl = null;
        _this.images = [];
        _this.index = null;
        _this.posts = [];
        return _this;
    }
    CzMenu.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.settings = store.state.settings;
                if (this.settings.Mainlogo !== null) {
                    this.logoUrl = this.settings.Uploads + "/" + this.settings.Mainlogo;
                }
                this.loadGallery();
                this.loadPosts();
                return [2 /*return*/];
            });
        });
    };
    CzMenu.prototype.loadPosts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = Settings.HostName + "/api/cztextblocks/list-with-users?count=2";
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _a.sent();
                        if (data == null) {
                            console.error("Internal server error getting last posts");
                            return [2 /*return*/];
                        }
                        this.posts = data;
                        return [2 /*return*/];
                }
            });
        });
    };
    CzMenu.prototype.getImage = function (name) {
        if (name !== undefined && name !== "" && name !== null) {
            return this.settings.Uploads + "/" + name;
        }
        return this.settings.Uploads + "/" + this.settings.Mainlogo;
    };
    CzMenu.prototype.logInOut = function () {
        if (store.state.loggedIn !== true) {
            store.commit("openLogin", window.location.pathname);
            return;
        }
        new ShopService().removeCart();
        LocalStorage.delete("ctk");
        LocalStorage.delete("cc");
        window.location.reload();
    };
    CzMenu.prototype.loadGallery = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dbGallery, url, data, _i, _a, image;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dbGallery = localStorage.getItem(Settings.Prefix + "all-gallery");
                        if (Vue.prototype.$last !== "/" && dbGallery !== null) {
                            this.images = JSON.parse(dbGallery).map(function (y) { return y.url; });
                            return [2 /*return*/];
                        }
                        url = Settings.HostName + "/api/fileuploads/open-list?parentName=CZConfig&parentId=1&category=CzGallery";
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _b.sent();
                        if (data == null || !data.ok) {
                            console.error("Internal server error getting cz gallery");
                            return [2 /*return*/];
                        }
                        for (_i = 0, _a = data.data; _i < _a.length; _i++) {
                            image = _a[_i];
                            image.url = this.settings.Uploads + "/CzConfig/CzGallery/" + image.url;
                            this.images.push(image.url);
                        }
                        localStorage.setItem(Settings.Prefix + "all-gallery", JSON.stringify(data.data));
                        return [2 /*return*/];
                }
            });
        });
    };
    CzMenu.prototype.toggleMenu = function () {
        var button = this.$refs.toggleButton;
        var menu = this.$refs.toggleMenu;
        if (!button.classList.contains("active")) {
            return;
        }
        button.classList.remove("active");
        menu.classList.remove("active");
    };
    __decorate([
        Watch("$route.fullPath")
    ], CzMenu.prototype, "toggleMenu", null);
    CzMenu = __decorate([
        Component({
            components: {
                VueGallery: VueGallery,
                Login: Login,
                MenuShopCart: MenuShopCart,
                MenuSearch: MenuSearch,
                MenuUpper: MenuUpper,
            },
        })
    ], CzMenu);
    return CzMenu;
}(Vue));
export default CzMenu;
