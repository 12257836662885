import store from "../store";
var DateHelper = /** @class */ (function () {
    function DateHelper() {
    }
    // input must be in system format
    DateHelper.toCultureString = function (input) {
        var date = this.toDate(input, this.systemFormat);
        return this.formatDate(date);
    };
    // input must be in system format
    DateHelper.toSystemDate = function (input) {
        return this.toDate(input, this.systemFormat);
    };
    DateHelper.toCultureTimeString = function (date) {
        var culture = localStorage.getItem(window.location.host + "_culture");
        if (culture === null) {
            culture = "en-US";
        }
        return date.toLocaleTimeString(culture, {
            hour: "2-digit",
            minute: "2-digit",
        });
    };
    DateHelper.toDate = function (input, format) {
        if (format === void 0) { format = ""; }
        if (input == "" || input == undefined || input == null) {
            console.error("input string in ToDate is null");
            return;
        }
        if (format == "") {
            format = String(localStorage.getItem(window.location.host + "_dateFormat"));
        }
        if (input == null || input == "")
            return;
        var formatParts = format.match(/(\w+)/g);
        if (formatParts == null)
            return;
        var valueParts = input.match(/(\d+)/g);
        if (valueParts == null)
            return;
        var day = 0, month = 0, year = 0, hour = 0, min = 0;
        for (var i = formatParts.length - 1; i >= 0; i--) {
            switch (formatParts[i]) {
                case "dd":
                case "d":
                    day = parseInt(valueParts[i]);
                    break;
                case "M":
                case "MM":
                    month = parseInt(valueParts[i]) - 1; // months starts at 0
                    break;
                case "yyyy":
                case "yy":
                    year = parseInt(valueParts[i]);
                    if (year < 100) {
                        year += 2000;
                    }
                    break;
                case "mm":
                    min = valueParts.length > i ? parseInt(valueParts[i]) : 0;
                    break;
                case "H":
                case "HH":
                    hour = valueParts.length > i ? parseInt(valueParts[i]) : 0;
                    break;
            }
        }
        return new Date(year, month, day, hour, min);
    };
    DateHelper.toSystemString = function (date) {
        return this.formatDate(date, this.systemFormat);
    };
    /// Date to string
    DateHelper.formatDate = function (date, format) {
        if (format === void 0) { format = ""; }
        if (date == undefined || date == null) {
            console.error("date in formatDate is null");
            return "";
        }
        if (!format) {
            format = store.state.settings.DateFormat ? store.state.settings.DateFormat : this.systemFormat;
        }
        var result = format;
        if (format.indexOf("yyyy") != -1) {
            result = result.replace("yyyy", date.getFullYear().toString());
        }
        else if (format.indexOf("yy") != -1) {
            result = result.replace("yy", date.getFullYear().toString());
        }
        var month = this.pad0(date.getMonth() + 1);
        if (format.indexOf("MM") != -1) {
            result = result.replace("MM", month);
        }
        else if (format.indexOf("M") != -1) {
            result = result.replace("M", month);
        }
        var day = date.getDate();
        var strDay = this.pad0(day);
        if (format.indexOf("dd") != -1) {
            result = result.replace("dd", strDay);
        }
        else if (format.indexOf("d") != -1) {
            result = result.replace("d", strDay);
        }
        var hour = this.pad0(date.getHours());
        if (format.indexOf("HH") != -1) {
            result = result.replace("HH", hour);
        }
        else if (format.indexOf("H") != -1) {
            result = result.replace("H", hour);
        }
        else if (format.indexOf("h") != -1) {
            var dHours = date.getHours();
            if (dHours > 12) {
                hour = (dHours - 12).toString();
                result = result.replace("tt", "pm");
            }
            else {
                result = result.replace("tt", "am");
            }
            result = result.replace("h", hour);
        }
        if (format.indexOf("mm") != -1) {
            var min = this.pad0(date.getMinutes());
            result = result.replace("mm", min);
        }
        return result;
    };
    ;
    DateHelper.pad0 = function (input) {
        var inputStr = input.toString();
        if (input < 10) {
            inputStr = 0 + inputStr;
        }
        return inputStr;
    };
    DateHelper.addMinutesToHourString = function (time, minsToAdd) {
        function D(J) { return (J < 10 ? '0' : '') + J; }
        ;
        var piece = time.split(':'); // todo: find separator by culture
        var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
        return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
    };
    DateHelper.dateAdd = function (date, interval, units) {
        if (date == null)
            return;
        var ret = new Date(date); //don't change original date
        var checkRollover = function () { if (ret.getDate() != date.getDate())
            ret.setDate(0); };
        switch (String(interval).toLowerCase()) {
            case 'year':
                ret.setFullYear(ret.getFullYear() + units);
                checkRollover();
                break;
            case 'quarter':
                ret.setMonth(ret.getMonth() + 3 * units);
                checkRollover();
                break;
            case 'month':
                ret.setMonth(ret.getMonth() + units);
                checkRollover();
                break;
            case 'week':
                ret.setDate(ret.getDate() + 7 * units);
                break;
            case 'day':
                ret.setDate(ret.getDate() + units);
                break;
            case 'hour':
                ret.setTime(ret.getTime() + units * 3600000);
                break;
            case 'minute':
                ret.setTime(ret.getTime() + units * 60000);
                break;
            case 'second':
                ret.setTime(ret.getTime() + units * 1000);
                break;
            default: return null;
        }
        return ret;
    };
    DateHelper.addSeconds = function (date, secs) {
        return this.dateAdd(date, 'second', secs);
    };
    DateHelper.addMinutes = function (date, mins) {
        return this.dateAdd(date, 'minute', mins);
    };
    DateHelper.addHours = function (date, hours) {
        return this.dateAdd(date, 'hour', hours);
    };
    DateHelper.addDays = function (date, days) {
        return this.dateAdd(date, 'day', days);
    };
    DateHelper.addWeeks = function (date, weeks) {
        return this.dateAdd(date, 'week', weeks);
    };
    DateHelper.addMonths = function (date, months) {
        return this.dateAdd(date, 'month', months);
    };
    DateHelper.addYears = function (date, years) {
        return this.dateAdd(date, 'year', years);
    };
    // checks if date 2 is full contained in date1
    DateHelper.IsContained = function (start1, end1, start2, end2) {
        if (start1 <= start2 && end2 <= end1) {
            return true;
        }
        return false;
    };
    DateHelper.isInDayOfWeek = function (date, dayOfWeek) {
        if (date === undefined) {
            return false;
        }
        var dateDayOfWeek = date.getDay();
        return dateDayOfWeek === dayOfWeek;
    };
    DateHelper.getTextMonth = function (Vue, month) {
        switch (month) {
            case 0: return Vue.$t("January");
            case 1: return Vue.$t("February");
            case 2: return Vue.$t("March");
            case 3: return Vue.$t("April");
            case 4: return Vue.$t("May");
            case 5: return Vue.$t("June");
            case 6: return Vue.$t("July");
            case 7: return Vue.$t("August");
            case 8: return Vue.$t("September");
            case 9: return Vue.$t("October");
            case 10: return Vue.$t("November");
            case 11: return Vue.$t("December");
        }
    };
    DateHelper.systemFormat = "yyyy-MM-dd HH:mm";
    return DateHelper;
}());
export { DateHelper };
