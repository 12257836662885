var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalStorage } from "./localStorage";
import Vue from "vue";
import Vuex from "vuex";
import { SettingsService } from "./services/configService";
Vue.use(Vuex);
var settings = {};
var loginIsOpen = false;
var loginRedirect = "";
var globalErrorIsOpen = false;
var globalErrorMsg = "";
var cart = LocalStorage.getWithTimeout("crt");
var loggedIn = LocalStorage.getWithTimeout("ctk") !== null;
var state = {
    settings: settings,
    loginIsOpen: loginIsOpen,
    loginRedirect: loginRedirect,
    globalErrorIsOpen: globalErrorIsOpen,
    globalErrorMsg: globalErrorMsg,
    loggedIn: loggedIn,
    cart: cart
};
var mutations = {
    openLogin: function (state, backUrl) {
        state.loginIsOpen = true;
        state.loginRedirect = backUrl;
    },
    closeLogin: function (state) {
        state.loginIsOpen = false;
    },
    setCart: function (state, value) {
        state.cart = value;
    },
    setSettings: function (state, settings) {
        state.settings = settings;
    },
    openGlobalError: function (state, msg) {
        state.globalErrorIsOpen = true;
        state.globalErrorMsg = msg;
    },
    closeGlobalError: function (state) {
        state.globalErrorIsOpen = false;
        state.globalErrorMsg = "";
    },
};
var actions = {
    openLogin: function (_a) {
        var commit = _a.commit;
        return commit('openLogin');
    },
    closeLogin: function (_a) {
        var commit = _a.commit;
        return commit('closeLogin');
    },
    openGlobalError: function (_a) {
        var commit = _a.commit;
        return commit('openGlobalError');
    },
    closeGlobalError: function (_a) {
        var commit = _a.commit;
        return commit('closeGlobalError');
    },
    loadSettings: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var settings;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, new SettingsService().loadConfig()];
                    case 1:
                        settings = _b.sent();
                        commit("setSettings", settings);
                        return [2 /*return*/];
                }
            });
        });
    }
};
var getters = {};
export default new Vuex.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
});
