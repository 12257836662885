





import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";

@Component
export default class TimeTable extends Vue {
  @Prop() cellData!: Object;
  @Prop() resources!: any[];

  getTime(txtTime: string) {
    if (txtTime === "") {
      return;
    }
    var format = String(
      localStorage.getItem(window.location.host + "_dateFormat")
    );
    if (format.indexOf("tt") !== -1) {
      // 12 hour format
      return txtTime;
    }

    // parse 12 hour format to 24
    var now = new Date();
    const dateNoTime: string =
      now.getMonth() + 1 + "/" + now.getDate() + "/" + now.getFullYear();
    const currentDateTime = new Date(dateNoTime + " " + txtTime);
    var parts = DateHelper.formatDate(currentDateTime).split(" ");
    return parts[1];
  }
}
