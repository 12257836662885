var Calcs = /** @class */ (function () {
    function Calcs() {
    }
    Calcs.prototype.dec = function (n, d) {
        var c = Math.pow(10, d);
        return Math.round(c * n) / c;
    };
    Calcs.prototype.snap = function (n, d) {
        return Math.round(n / d) * d;
    };
    Calcs.prototype.sign = function (n) {
        var nn = 1;
        if (n < 0) {
            nn = -1;
        }
        if (n === 0) {
            nn = 0;
        }
        return nn;
    };
    Calcs.prototype.arcDirection = function (hx1, hy1, dx1, dy1, arcdire) {
        var sgn = 1;
        if (arcdire === "0") {
            sgn = -1;
        }
        var hx2 = hx1 - dx1;
        var hy2 = hy1 - dy1;
        var inv = this.arcRevert(hx1, hy1, hx2, hy2);
        var sgn3 = sgn * inv;
        if (sgn3 === -1) {
            arcdire = "0";
        }
        if (sgn3 === 1) {
            arcdire = "1";
        }
        return arcdire;
    };
    Calcs.prototype.arcRevert = function (hx1, hy1, hx2, hy2) {
        var sx1 = this.sign(hx1);
        var sy1 = this.sign(hy1);
        var sx2 = this.sign(hx2);
        var sy2 = this.sign(hy2);
        var inv = -1;
        if (sx1 === -1 && sy1 === 1 && sx2 === -1 && sy2 === 1) {
            inv = 1;
        }
        if (sx1 === -1 && sy1 === -1 && sx2 === -1 && sy2 === -1) {
            inv = 1;
        }
        if (sx1 === 1 && sy1 === 1 && sx2 === 1 && sy2 === 1) {
            inv = 1;
        }
        if (sx1 === 1 && sy1 === -1 && sx2 === 1 && sy2 === -1) {
            inv = 1;
        }
        if (sx1 === 0) {
            if (sy1 === 1 && sy2 === 1) {
                inv = 1;
            }
            if (sy1 === -1 && sy2 === -1) {
                inv = 1;
            }
        }
        if (sy1 === 0) {
            if (sx1 === 1 && sx2 === 1) {
                inv = 1;
            }
            if (sx1 === -1 && sx2 === -1) {
                inv = 1;
            }
        }
        return inv;
    };
    Calcs.prototype.ratioArc = function (dx, dy, hx1, hy1, dx1, dy1) {
        var d = Math.hypot(dx, dy);
        var hx2 = hx1 - dx1;
        var hy2 = hy1 - dy1;
        var h2 = Math.hypot(hx2, hy2);
        var d2 = d / 2;
        var an2 = Math.atan(Math.abs(h2) / d2);
        var beta = 2 * an2;
        var r = Math.abs(d2 / Math.sin(beta));
        return r;
    };
    Calcs.prototype.InsidePolygon = function ($polygon, $p) {
        var $counter = 0;
        var $p1 = [];
        var $p2 = [];
        var $n = $polygon.length;
        $p1 = $polygon[0];
        var $xinters;
        var $cc1;
        var $cc2;
        for (var $i = 0; $i < $n; $i++) {
            $p2 = $polygon[$i % $n];
            if ($p.y > this.getMin($p1.y, $p2.y)) {
                if ($p.y <= this.getMax($p1.y, $p2.y)) {
                    if ($p.x <= this.getMax($p1.x, $p2.x)) {
                        if ($p1.y !== $p2.y) {
                            $xinters =
                                (($p.y - $p1.y) * ($p2.x - $p1.x)) / ($p2.y - $p1.y) + $p1.x;
                            $cc1 = ($p.y - $p1.y) * ($p2.x - $p1.x);
                            $cc2 = $p2.y - $p1.y;
                            $xinters = $cc1 / $cc2 + $p1.x;
                            if ($p1.x === $p2.x || $p.x <= $xinters) {
                                $counter++;
                            }
                        }
                    }
                }
            }
            $p1 = $p2;
        }
        if ($counter % 2 === 0) {
            return 0;
        }
        else {
            return 1;
        }
    };
    Calcs.prototype.getMin = function ($a, $b) {
        var $c;
        if ($a < $b) {
            $c = $a;
        }
        else {
            $c = $b;
        }
        return $c;
    };
    Calcs.prototype.getMax = function ($a, $b) {
        var $c;
        if ($a > $b) {
            $c = $a;
        }
        else {
            $c = $b;
        }
        return $c;
    };
    return Calcs;
}());
export { Calcs };
