var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModifyMode } from "../constants";
import { DateHelper } from "../helpers/dateHelper";
import { LocalStorage } from "../localStorage";
import store from "../store";
import { Settings } from "./configService";
import { CrudService } from "./crudService";
var ShopService = /** @class */ (function () {
    function ShopService() {
    }
    ShopService.prototype.addProductToCart = function (product, units, dom) {
        return __awaiter(this, void 0, void 0, function () {
            var s;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        s = {
                            id: 0,
                            productId: product.id,
                            buyerCustomerId: this.getLoggedCustomer(),
                            customerId: this.getLoggedCustomer(),
                            saleId: 0,
                            units: units,
                            temp: true,
                            subTotal: product.grossPrice * units,
                            totalWithDiscount: product.grossPrice * units,
                            product: product,
                            taxPercent: 21,
                            buyerCustomerTypeName: "-",
                            customerTypeName: "-",
                            parentIdentifier: product.name,
                        };
                        if (product.hasStock === true) {
                            s.stockParentId = s.productId;
                            s.stockParentName = "Product";
                        }
                        return [4 /*yield*/, this.addToCart(s, dom)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopService.prototype.animationToCart = function (elem) {
        if (elem === null || elem === undefined) {
            return;
        }
        var div = document.createElement("div");
        div.classList.add("animationCart");
        var viewportOffset = elem.getBoundingClientRect();
        var x = viewportOffset.top + window.scrollY;
        var y = viewportOffset.left + +window.scrollX;
        div.style.top = x + "px";
        div.style.left = y + "px";
        var app = document.getElementById("app");
        if (app == null) {
            return;
        }
        app.appendChild(div);
        var target = document.getElementById("menuShopCart");
        var tviewPort = target.getBoundingClientRect();
        var xT = tviewPort.top + window.scrollY;
        var yT = tviewPort.left + +window.scrollX;
        div.style.left = yT + 'px';
        div.style.top = xT + 'px';
        setTimeout(function () {
            if (div.parentNode === null) {
                return;
            }
            div.parentNode.removeChild(div);
        }, 1000);
    };
    ShopService.prototype.addSaleToCart = function (sale, dom) {
        var cart = this.getCart();
        if (cart === null) {
            cart = {
                sales: [sale],
                sItems: [],
                total: sale.pending,
                count: 1
            };
            this.preBookingMinutesCheck(sale);
            this.saveCart(cart);
            return;
        }
        var found = cart.sales.find(function (y) { return y.id == sale.id; });
        if (found !== undefined && found !== null) {
            return;
        }
        else {
            cart.sales.push(sale);
        }
        this.preBookingMinutesCheck(sale);
        this.updateCart(cart);
        this.animationToCart(dom);
    };
    ShopService.prototype.preBookingMinutesCheck = function (sale) {
        var _this = this;
        if (sale === undefined || sale === null || sale.preBookingMinutes === undefined || sale.preBookingMinutes === null) {
            return;
        }
        setTimeout(function () {
            _this.removeSaleFromCart(sale.id);
        }, sale.preBookingMinutes * 60 * 1000);
    };
    ShopService.prototype.removeSaleFromCart = function (saleId, cancelServer) {
        if (cancelServer === void 0) { cancelServer = false; }
        return __awaiter(this, void 0, void 0, function () {
            var cart, sale, url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cart = this.getCart();
                        if (cart === null) {
                            return [2 /*return*/];
                        }
                        sale = cart.sales.find(function (y) { return y.id === saleId; });
                        if (!(cancelServer && sale !== undefined && sale.cancelUrl !== undefined && sale.cancelUrl !== null)) return [3 /*break*/, 2];
                        url = Settings.HostName + "/api/" + sale.cancelUrl;
                        return [4 /*yield*/, new CrudService().httpPost(url)];
                    case 1:
                        data = _a.sent();
                        if (data == null || data.ok === false) {
                            console.error("Internal server error cancelling in cart " + data.errorMsg);
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        cart.sales = cart.sales.filter(function (el) { return el.id !== sale.id; });
                        this.updateCart(cart);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopService.prototype.removeSaleItemFromCart = function (saleItem) {
        return __awaiter(this, void 0, void 0, function () {
            var cart, cartSaleItem;
            return __generator(this, function (_a) {
                cart = this.getCart();
                if (cart === null) {
                    return [2 /*return*/];
                }
                cartSaleItem = this.findSaleItem(cart, saleItem);
                cart.sItems = cart.sItems.filter(function (el) { return el !== cartSaleItem; });
                this.updateCart(cart);
                return [2 /*return*/];
            });
        });
    };
    ShopService.prototype.modifyUnits = function (mode, saleItem, units) {
        return __awaiter(this, void 0, void 0, function () {
            var cart, found;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cart = this.getCart();
                        if (cart === null) {
                            return [2 /*return*/];
                        }
                        found = this.findSaleItem(cart, saleItem);
                        if (found === undefined && found === null) {
                            return [2 /*return*/];
                        }
                        found.saleId = saleItem.saleId;
                        if (!(mode === ModifyMode.Decrement)) return [3 /*break*/, 1];
                        found.units = found.units <= 1 ? 0 : found.units - units;
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(mode === ModifyMode.Increment)) return [3 /*break*/, 3];
                        found.units += units;
                        return [4 /*yield*/, this.stockIsAvailable(found, cart, true)];
                    case 2:
                        if ((_a.sent()) === false) {
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        found.units = units;
                        _a.label = 4;
                    case 4:
                        found.subTotal = found.product.grossPrice * found.units;
                        found.totalWithDiscount = found.subTotal; // - discount
                        this.updateCart(cart);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopService.prototype.addToCart = function (saleItem, dom) {
        return __awaiter(this, void 0, void 0, function () {
            var cart, found, stockOk;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cart = this.getCart();
                        if (cart === null) {
                            cart = {
                                sItems: [],
                                sales: [],
                                total: 0,
                                count: 0
                            };
                        }
                        found = this.findSaleItem(cart, saleItem);
                        if (found !== undefined && found !== null) {
                            this.modifyUnits(ModifyMode.Increment, saleItem, saleItem.units);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.stockIsAvailable(saleItem, cart, false)];
                    case 1:
                        stockOk = _a.sent();
                        if (stockOk === true) {
                            cart.sItems.push(saleItem);
                            this.updateCart(cart);
                            this.animationToCart(dom);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopService.prototype.stockIsAvailable = function (s, cart, updating) {
        return __awaiter(this, void 0, void 0, function () {
            var url, tmpCart, _i, _a, item, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (s.product === null || s.product === undefined) {
                            console.error("no product in saleitem");
                            return [2 /*return*/, false];
                        }
                        if (!(s.product.hasStock === true)) return [3 /*break*/, 2];
                        url = Settings.HostName + "/api/publicpayments/check-stock";
                        tmpCart = [];
                        if (updating === false) {
                            for (_i = 0, _a = cart.sItems; _i < _a.length; _i++) {
                                item = _a[_i];
                                tmpCart.push({ units: item.units, temp: item.temp, stockParentId: item.stockParentId, stockParentName: item.stockParentName, stockDetail: item.stockDetail, productId: item.productId });
                            }
                        }
                        tmpCart.push({ units: s.units, temp: s.temp, stockParentId: s.stockParentId, stockParentName: s.stockParentName, stockDetail: s.stockDetail, productId: s.productId });
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                customerId: s.customerId,
                                saleItemsDto: tmpCart,
                            })];
                    case 1:
                        data = _b.sent();
                        if (data == null || data.ok === false) {
                            store.commit("openGlobalError", data.error);
                            return [2 /*return*/, false];
                        }
                        if (data.ok === undefined) {
                            store.commit("openGlobalError", "Internal server error. Please try again later");
                            return [2 /*return*/, false];
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    ShopService.prototype.updateCart = function (cart) {
        var salesTotal = cart.sales.reduce(function (a, b) { return a + b.pending; }, 0);
        cart.total = cart.sItems.reduce(function (a, b) { return a + b.totalWithDiscount; }, 0) + salesTotal;
        cart.count = cart.sItems.reduce(function (a, b) { return a + b.units; }, 0) + cart.sales.length;
        this.saveCart(cart);
    };
    ShopService.prototype.findSaleItem = function (cart, saleItem) {
        var found = cart.sItems.find(function (y) {
            return y.productId === saleItem.productId &&
                y.parentId === saleItem.parentId &&
                y.parentName === saleItem.parentName;
        });
        return found;
    };
    ShopService.prototype.removeCart = function () {
        LocalStorage.delete("crt");
        store.commit("setCart", null);
    };
    ShopService.prototype.saveCart = function (cart) {
        var expiry = DateHelper.addDays(new Date(), 24);
        LocalStorage.delete("crt");
        LocalStorage.addWithTimout("crt", cart, expiry != null && expiry !== undefined ? expiry : new Date());
        store.commit("setCart", cart);
    };
    ShopService.prototype.getCart = function () {
        var sCrt = LocalStorage.getWithTimeout("crt");
        if (sCrt !== null && sCrt !== "") {
            return sCrt;
        }
        return null;
    };
    ShopService.prototype.getLoggedCustomer = function () {
        var cid = -1;
        var sid = LocalStorage.getWithTimeout("cc");
        if (sid !== undefined && sid !== null) {
            cid = JSON.parse(sid).id;
        }
        return cid;
    };
    return ShopService;
}());
export { ShopService };
