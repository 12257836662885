





import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";

@Component
export default class DataCell extends Vue {
  @Prop() cellData!: Object;
  @Prop() resources!: any[];

  markDataCell(cellData) {
    // const date = cellData.startDate;
    if (this.resources === undefined || this.resources === null) {
      return;
    }

    if (cellData.groups === undefined) {
      return;
    }

    var resourceTimes = this.resources.filter(
      (t) => t.resourceId == cellData.groups.resourceId
    );

    for (var i = 0, l = resourceTimes.length; i < l; i++) {
      var time = resourceTimes[i];
      var day = cellData.startDate.getDate();

      const dateNoTime: string =
        cellData.startDate.getMonth() +
        1 +
        "/" +
        day +
        "/" +
        cellData.startDate.getFullYear();

      const openResource = new Date(dateNoTime + " " + time.start);
      var closeResource: any = new Date(dateNoTime + " " + time.end);

      if (time.end === "00:00") {
        // next day end
        closeResource = DateHelper.addDays(new Date(dateNoTime + " 00:00"), 1);
      }

      if (
        DateHelper.IsContained(
          openResource,
          closeResource,
          cellData.startDate,
          cellData.endDate
        )
      ) {
        return {
          "disable-date": false,
        };
      }
    }

    return {
      "disable-date": true,
    };
  }
}
