var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import { LocalStorage } from "../core/localStorage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { DateHelper } from "../core/helpers/dateHelper";
import { Format } from "../core/helpers/formatHelper";
import Popup from "../components/popup/popup.vue";
import { ShopService } from "../core/services/shopService";
import DatePicker from "../components/datePicker/datePicker.vue";
import { DatePickerMode } from "../core/constants";
import { Loader } from "../core/helpers/loaderHelper";
import BookingsFilters from "./bookingsFilters.vue";
var BookingsByPlacePage = /** @class */ (function (_super) {
    __extends(BookingsByPlacePage, _super);
    function BookingsByPlacePage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.anyByType = false;
        _this.bookAllowed = false;
        _this.errorMsg = "";
        _this.date = "";
        _this.bookingTypeId = null;
        _this.data = [];
        _this.dataByDays = [];
        _this.bookingTypes = [];
        _this.bookingTypesByPlace = [];
        _this.showPopUpConfirm = false;
        _this.bookingSelected = {};
        _this.carouselSettings = null;
        _this.loaded = false;
        _this.closeFilter = 0;
        _this.openFilter = 0;
        return _this;
    }
    BookingsByPlacePage.prototype.mounted = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var url, res;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = Settings.HostName + "/api/publicbookings/filters";
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        res = _b.sent();
                        if (res == null || res.ok === false) {
                            console.error("Internal server error getting filters");
                            this.errorMsg = this.$t("Internal server error. Please try again later").toString();
                            return [2 /*return*/];
                        }
                        this.bookingTypes = res.model.bookingTypes;
                        if (this.bookingTypes === null ||
                            this.bookingTypes === undefined ||
                            this.bookingTypes.length === 0) {
                            this.errorMsg = this.$t("No bookings by place available").toString();
                            return [2 /*return*/];
                        }
                        this.bookingTypesByPlace = this.bookingTypes.filter(function (y) { return y.byPlace === true; });
                        if (this.bookingTypes.length === 1) {
                            this.bookingTypeId = this.bookingTypes[0].id;
                            this.bookingTypes = [];
                        }
                        if (this.$route.query.date !== undefined) {
                            this.date = (_a = this.$route.query.date) === null || _a === void 0 ? void 0 : _a.toString();
                        }
                        if (this.date === null || this.date === "" || this.data === undefined) {
                            this.date = DateHelper.toSystemString(new Date());
                        }
                        if (this.$route.params.bookingTypeId !== undefined) {
                            this.bookingTypeId = Number(this.$route.params.bookingTypeId);
                        }
                        return [4 /*yield*/, this.loadData()];
                    case 2:
                        _b.sent();
                        this.loaded = true;
                        if (this.$route.query.bookingId !== undefined) {
                            this.bookingSelected = this.data.find(function (y) { return y.id == Number(_this.$route.query.bookingId); });
                            if (this.bookingSelected === undefined) {
                                console.error("wrong url booking id param" + this.$route.query.bookingId);
                                this.errorMsg = this.$t("Ups something went wrong Please try again").toString();
                                return [2 /*return*/];
                            }
                            this.showPopUpConfirm = true;
                        }
                        this.carouselSettings = {
                            speed: 500,
                            slidesToShow: 8,
                            slidesToScroll: 8,
                            centerMode: this.data.length < 8,
                            responsive: [
                                {
                                    breakpoint: 1224,
                                    settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 3,
                                        infinite: true,
                                    },
                                },
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                        infinite: true,
                                    },
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                        initialSlide: 2,
                                    },
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    },
                                },
                            ],
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsByPlacePage.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, url, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMsg = "";
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        this.data = [];
                        this.dataByDays = [];
                        url = Settings.HostName + "/api/publicbookings/byplace-list";
                        Loader.Loading("loader");
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                date: this.date,
                                bookingTypeId: this.bookingTypeId,
                                customerId: cid === 0 ? null : cid,
                            })];
                    case 1:
                        res = _a.sent();
                        Loader.Unloading("loader");
                        if (res == null || res == undefined) {
                            console.error("Internal server error getting bookings by place");
                            return [2 /*return*/];
                        }
                        if (res.ok === false) {
                            this.errorMsg = res.errorMsg;
                            return [2 /*return*/];
                        }
                        if (res.data === null || res.data === undefined || res.data.length === 0) {
                            this.errorMsg = this.$t("No bookings found").toString();
                            return [2 /*return*/];
                        }
                        this.data = res.data;
                        this.setUpData();
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsByPlacePage.prototype.setUpData = function () {
        this.dataByDays = [];
        var mondays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 1);
        });
        if (mondays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Mondays"),
                data: mondays,
            });
        }
        var tuesdays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 2);
        });
        if (tuesdays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Tuesdays"),
                data: tuesdays,
            });
        }
        var wenesdays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 3);
        });
        if (wenesdays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Wenesdays"),
                data: wenesdays,
            });
        }
        var thursdays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 4);
        });
        if (thursdays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Thursdays"),
                data: thursdays,
            });
        }
        var fridays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 5);
        });
        if (fridays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Fridays"),
                data: fridays,
            });
        }
        var saturdays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 6);
        });
        if (saturdays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Saturdays"),
                data: saturdays,
            });
        }
        var sundays = this.data.filter(function (y) {
            return DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 0);
        });
        if (sundays.length > 0) {
            this.dataByDays.push({
                title: this.$t("Sundays"),
                data: sundays,
            });
        }
    };
    BookingsByPlacePage.prototype.book = function (e, id) {
        this.errorMsg = "";
        var booking = this.data.find(function (y) { return y.id === id; });
        if (booking === undefined) {
            console.error("not booking found " + id);
        }
        if (booking.bookingTemplateId !== null &&
            booking.bookingTemplateId !== undefined) {
            return this.$router.push("/booking-visual/" +
                id +
                "/" +
                booking.bookingTemplateId +
                "?date=" +
                this.date);
        }
        this.bookingSelected = booking;
        this.showPopUpConfirm = true;
        return this.$router.push("/bookings-byplace?bookingId=" + id + "&date=" + this.date);
    };
    BookingsByPlacePage.prototype.addToCart = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, bookingId, url, res, sale;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMsg = "";
                        e.preventDefault();
                        cid = 0;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        if (cid === 0 || cid === null) {
                            this.errorMsg = this.$t("Please login or register").toString();
                            return [2 /*return*/];
                        }
                        bookingId = this.bookingSelected.id;
                        if (bookingId === null || bookingId === undefined) {
                            this.errorMsg = this.$t("Ups something went wrong Please try again").toString();
                            return [2 /*return*/];
                        }
                        url = Settings.HostName + "/api/publicbookings/byplace-prebook-place/" + cid + "/" + bookingId;
                        if (this.$route.query.placeId !== undefined) {
                            url += "/" + this.$route.query.placeId;
                        }
                        return [4 /*yield*/, new CrudService().httpPost(url)];
                    case 1:
                        res = _a.sent();
                        if (res == null || res == undefined) {
                            console.error("Internal server error getting bookings by place");
                            this.errorMsg = this.$t("Internal server error").toString();
                            return [2 /*return*/];
                        }
                        if (res.ok === false) {
                            this.errorMsg = res.errorMsg;
                            return [2 /*return*/];
                        }
                        if (res.model.serviceFree === true) {
                            return [2 /*return*/, this.$router.push("/customer-bookings")];
                        }
                        sale = res.model;
                        sale.title = sale.description;
                        sale.cancelUrl = "publicbookings/cancel/" + bookingId + "/" + cid;
                        new ShopService().addSaleToCart(sale, e.currentTarget);
                        this.$router.push("/checkout");
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingsByPlacePage.prototype.getPrice = function (price) {
        return Format.formatCurrency(price);
    };
    BookingsByPlacePage.prototype.bookingTypeSelected = function (bType) {
        if (bType.byPlace === true) {
            this.filterByBookingType(bType.id, null);
            return;
        }
        return this.$router.push("/bookings/" + bType.id);
    };
    BookingsByPlacePage.prototype.filterByBookingType = function (id, e) {
        if (e !== null) {
            e.preventDefault();
        }
        this.bookingTypeId = id;
        this.loadData();
    };
    BookingsByPlacePage.prototype.filterByDate = function (date) {
        if (this.loaded === false) {
            return;
        }
        this.date = date.systemStrDate;
        this.loadData();
    };
    BookingsByPlacePage.prototype.datePickerMode = function () {
        return DatePickerMode.Date;
    };
    BookingsByPlacePage.prototype.closeFilters = function () {
        this.closeFilter++; // to trigger watch
    };
    BookingsByPlacePage.prototype.openFilters = function () {
        this.openFilter++; // to trigger watch
    };
    BookingsByPlacePage = __decorate([
        Component({
            components: {
                VueSlickCarousel: VueSlickCarousel,
                Popup: Popup,
                DatePicker: DatePicker,
                BookingsFilters: BookingsFilters,
            },
        })
    ], BookingsByPlacePage);
    return BookingsByPlacePage;
}(Vue));
export default BookingsByPlacePage;
