import { render, staticRenderFns } from "./myFeesPage.vue?vue&type=template&id=2b111134&scoped=true&"
import script from "./myFeesPage.vue?vue&type=script&lang=ts&"
export * from "./myFeesPage.vue?vue&type=script&lang=ts&"
import style0 from "./myFeesPage.vue?vue&type=style&index=0&id=2b111134&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../soccer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b111134",
  null
  
)

export default component.exports