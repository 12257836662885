var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CrudService } from "../../core/services/crudService";
import DataCell from "./dataCell.vue";
import TimeCell from "./timeCell.vue";
import BookingTemplate from "./bookingTemplate.vue";
import DataCellToolTipTemplate from "./dataCellToolTipTemplate.vue";
import { TimeRange } from "../../core/constants";
import { DateHelper } from "../../core/helpers/dateHelper";
import DxScheduler, { DxResource, DxView, DxEditing, } from "devextreme-vue/scheduler";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Settings } from "../../core/services/configService";
import { LocalStorage } from "../../core/localStorage";
import store from "../../core/store";
var TimeTable = /** @class */ (function (_super) {
    __extends(TimeTable, _super);
    function TimeTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.group = {};
        _this.scheduler = null;
        _this.currentDate = new Date();
        _this.dataSource = [];
        _this.resourcesData = [];
        _this.editing = { allowAdding: false };
        _this.data = null;
        _this.error = "";
        _this.bookingTypesPopUp = false;
        _this.saveError = null;
        _this.reloadSavePopup = 0;
        _this.cellSelected = null;
        _this.categorySelected = "";
        _this.currentView = "day";
        _this.reload = 0;
        _this.currentResourceGroupId = 0;
        _this.moveBookingPopUp = false;
        _this.updatePriceOnMove = false;
        _this.notifyOnMove = false;
        _this.showAddParticipantPopUp = false;
        _this.errorAddParticipant = "";
        _this.bookingId = 0;
        _this.timeRange = TimeRange.Days;
        _this.dateStr = "";
        _this.timeOutId = "";
        _this.milliseconds = 30000;
        return _this;
    }
    TimeTable.prototype.config = function () {
        var query = this.$route.query;
        if (query.date !== null && query.date !== "" && query.date !== undefined) {
            try {
                this.currentDate = DateHelper.toDate(query.date, DateHelper.systemFormat);
            }
            catch (_a) { }
        }
        this.getResources(this.currentDate);
    };
    TimeTable.prototype.onAppointmentDblClick = function (e) {
        e.cancel = true;
    };
    TimeTable.prototype.getResources = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            var url, data, _loop_1, this_1, _i, _a, y;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.dateStr = DateHelper.formatDate(date, DateHelper.systemFormat);
                        if (this.groups.length === 0) {
                            this.data = {
                                bookingBlocks: [],
                                bookingTypes: {},
                                resourceGroups: [
                                    {
                                        resourceGroup: { id: -1, name: "", intervalMinutes: 60 },
                                        start: 0,
                                        end: 24,
                                    },
                                ],
                            };
                            this.selectGroup(this.$route.query.group);
                            this.getBookings();
                            this.restartRefreshTimeOut();
                            return [2 /*return*/];
                        }
                        url = Settings.HostName + "/api/publictimetabledates/timetable-day";
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                date: this.dateStr,
                                timeRange: this.timeRange,
                            })];
                    case 1:
                        data = _b.sent();
                        if (data == null) {
                            this.error = this.$t("Internal server error").toString();
                            return [2 /*return*/];
                        }
                        if (!data.ok) {
                            this.error = data.error;
                            return [2 /*return*/];
                        }
                        this.data = data.data;
                        _loop_1 = function (y) {
                            if (this_1.dataSource.findIndex(function (x) { return x.id === y.id; }) === -1) {
                                this_1.dataSource.push(y);
                            }
                        };
                        this_1 = this;
                        for (_i = 0, _a = this.data.bookingBlocks; _i < _a.length; _i++) {
                            y = _a[_i];
                            _loop_1(y);
                        }
                        this.selectGroup(this.$route.query.group);
                        this.restartRefreshTimeOut();
                        return [2 /*return*/];
                }
            });
        });
    };
    TimeTable.prototype.onOptionChanged = function (value) {
        var _this = this;
        if (value.name === "currentDate") {
            this.$router.push({
                query: {
                    date: DateHelper.formatDate(value.value, DateHelper.systemFormat).split(" ")[0],
                    group: this.currentResourceGroupId.toString(),
                },
            });
            this.restartRefreshTimeOut();
            this.getResources(value.value);
        }
        if (value.name === "currentView") {
            this.currentView = value.value;
            if (value.value === "week") {
                this.timeRange = TimeRange.Weeks;
            }
            else if (value.value === "month") {
                this.timeRange = TimeRange.Months;
            }
            else {
                this.timeRange = TimeRange.Days;
            }
            this.restartRefreshTimeOut();
            this.getResources(this.currentDate);
            if (this.currentView === "agenda" && this.highLightValues !== undefined) {
                var onlyHighLighted = [];
                this.dataSource = [];
                var _loop_2 = function (i) {
                    var booking = this_2.dataSource[i];
                    found = this_2.highLightValues.find(function (y) { return y === booking[_this.highLightField]; });
                    if (found !== undefined) {
                        onlyHighLighted.push(booking);
                    }
                };
                var this_2 = this, found;
                for (var i = 0; i < this.dataSource.length; i++) {
                    _loop_2(i);
                }
                this.dataSource = onlyHighLighted;
                return;
            }
        }
    };
    TimeTable.prototype.selectGroup = function (groupId) {
        if (groupId !== null && groupId !== undefined) {
            this.group = this.data.resourceGroups.find(function (y) { return y.resourceGroup.id === Number(groupId); });
        }
        else {
            this.group = this.data.resourceGroups[0];
        }
        this.currentResourceGroupId = this.group.resourceGroup.id;
    };
    TimeTable.prototype.getBookings = function (refresh) {
        if (refresh === void 0) { refresh = true; }
        return __awaiter(this, void 0, void 0, function () {
            var url, cid, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!refresh) {
                            return [2 /*return*/];
                        }
                        url = Settings.HostName + "/api/bookings/get-bookings";
                        if (this.getBookingsUrl !== undefined && this.getBookingsUrl !== null) {
                            url = Settings.HostName + "/api/" + this.getBookingsUrl;
                        }
                        cid = 0;
                        try {
                            cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
                        }
                        catch (_b) {
                            console.error("not customer logged found");
                        }
                        return [4 /*yield*/, new CrudService().httpPost(url, {
                                customerId: cid,
                                date: this.dateStr,
                                timeRange: this.timeRange,
                            })];
                    case 1:
                        data = _a.sent();
                        if (data == null)
                            return [2 /*return*/];
                        if (!data.ok) {
                            return [2 /*return*/];
                        }
                        this.dataSource = data.model;
                        return [2 /*return*/];
                }
            });
        });
    };
    TimeTable.prototype.getBookingTypes = function () {
        return this.data.bookingTypes;
    };
    TimeTable.prototype.created = function () {
        this.config();
    };
    TimeTable.prototype.onContentReady = function (e) {
        this.scheduler = e.component;
        this.scheduler.hideAppointmentTooltip();
    };
    TimeTable.prototype.restartRefreshTimeOut = function () {
        this.clearTimeOut();
        this.setTimeOut();
    };
    TimeTable.prototype.setTimeOut = function () {
        this.timeOutId = setInterval(this.getBookings, this.milliseconds);
    };
    TimeTable.prototype.clearTimeOut = function () {
        clearInterval(this.timeOutId);
    };
    TimeTable.prototype.getUnicResources = function (resources) {
        var res = [];
        for (var i = 0, l = resources.length; i < l; i++) {
            var item = resources[i];
            if (res.findIndex(function (y) { return y.id == item.resourceId; }) === -1) {
                var text = item.resourceType !== null
                    ? item.text + " " + item.resourceType
                    : item.text;
                res.push({ id: item.resourceId, text: text });
            }
        }
        return res;
    };
    TimeTable.prototype.onCellClick = function (data) {
        if (data.cellElement.firstChild.classList.contains("disable-date")) {
            return;
        }
        if (this.cellClickUrl !== undefined) {
            if (this.cellClickUrl === "#") {
                return;
            }
            var urlClick = this.cellClickUrl +
                "?date=" +
                DateHelper.formatDate(data.cellData.startDate, DateHelper.systemFormat);
            if (this.parentId !== undefined && this.parentId !== null) {
                urlClick +=
                    "&parentId=" + this.parentId + "&parentName=" + this.parentName;
            }
            if (this.returnUrl !== undefined) {
                urlClick += "&returnUrl=" + this.returnUrl;
            }
            if (this.parentIdExtra !== undefined && this.parentIdExtra !== null) {
                urlClick +=
                    "&parentIdExtra=" +
                        this.parentIdExtra +
                        "&parentNameExtra=" +
                        this.parentNameExtra;
            }
            this.$router.push(urlClick);
        }
        // var forceReload = LocalStorage.get(
        //   Vue.prototype.$prefix,
        //   "timetable_reload"
        // );
        // if (forceReload === "true") {
        //   localStorage.removeItem(Vue.prototype.$prefix + "_" + "timetable_reload");
        //   this.getResources(this.currentDate);
        // }
        this.cellSelected = data.cellData;
        this.bookingTypesPopUp = true;
    };
    //   addBooking(bookingType: any) {
    //     if (this.cellSelected === null) {
    //       console.error("Not timetable cell selected");
    //       return;
    //     }
    //     var guid = StringHelper.uuidv4();
    //     var bookingData = {
    //       bookingType: bookingType,
    //       resourceId: this.cellSelected.groups.resourceId,
    //       start: this.cellSelected.startDate,
    //       currentResourceGroupId: this.currentResourceGroupId,
    //       parentDescription: this.parentDescription,
    //       parentName: this.parentName,
    //       parentId: this.parentId,
    //       returnUrl: this.returnUrl,
    //     };
    //     LocalStorage.addWithTimout("booking", guid, bookingData);
    //     this.$router.push({
    //       name: "addbooking",
    //       params: {
    //         id: guid,
    //       },
    //     });
    //   }
    TimeTable.prototype.setCategorySelected = function (category) {
        if (this.categorySelected === category) {
            this.categorySelected = "";
        }
        else {
            this.categorySelected = category;
        }
    };
    TimeTable.prototype.bookingTypeAllowed = function (bookingType) {
        var _this = this;
        var resourceId = this.cellSelected.groups.resourceId;
        if (bookingType.allowedResources.find(function (y) { return y === resourceId; })) {
            return true;
        }
        if (bookingType.allowedGroups.find(function (y) { return y === _this.currentResourceGroupId; })) {
            return true;
        }
        return false;
    };
    TimeTable.prototype.timezone = function () {
        return store.state.settings.TimeZone;
    };
    TimeTable.prototype.getCurrentView = function (group) {
        // if (group.resourceGroup.bookingBy === BookingBy.ByDays) {
        //   return "timelineMonth";
        // }
        if (this.groups.length == 0)
            return "month";
        return "day";
    };
    TimeTable.prototype.getEndDayHour = function (group) {
        if (group.end === 0) {
            return 24;
        }
        return group.end;
    };
    TimeTable.prototype.getHeight = function () {
        return this.groups === undefined || this.groups.length === 0 ? 800 : null;
    };
    TimeTable.prototype.hideLoading = function () {
        var loaderEl = this.$refs.loader;
        if (loaderEl == null)
            return;
        loaderEl.classList.add("fadeOut");
    };
    __decorate([
        Prop()
    ], TimeTable.prototype, "resourceGroupId", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "parentDescription", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "highLightField", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "highLightValues", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "cellClickUrl", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "parentName", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "parentId", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "parentNameExtra", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "parentIdExtra", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "onBookingClickUrl", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "returnUrl", void 0);
    __decorate([
        Prop()
    ], TimeTable.prototype, "getBookingsUrl", void 0);
    __decorate([
        Prop({
            default: function () {
                return [
                    "day",
                    "timelineDay",
                    "week",
                    //  "month",
                    // "timelineMonth",
                    "agenda",
                ];
            },
        })
    ], TimeTable.prototype, "views", void 0);
    __decorate([
        Prop({
            default: function () {
                return ["resourceId"];
            },
        })
    ], TimeTable.prototype, "groups", void 0);
    TimeTable = __decorate([
        Component({
            components: {
                DxScheduler: DxScheduler,
                DxResource: DxResource,
                DataCell: DataCell,
                TimeCell: TimeCell,
                DxEditing: DxEditing,
                DataCellToolTipTemplate: DataCellToolTipTemplate,
                BookingTemplate: BookingTemplate,
                DxView: DxView,
            },
        })
    ], TimeTable);
    return TimeTable;
}(Vue));
export default TimeTable;
