var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";
import Accordion from "../components/accordion/accordion.vue";
var BookingsFilters = /** @class */ (function (_super) {
    __extends(BookingsFilters, _super);
    function BookingsFilters() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.bookingTypesByCategory = [];
        _this.bookingTypesNoCategory = [];
        return _this;
    }
    BookingsFilters.prototype.mounted = function () {
        this.parseBookingTypes();
        this.setUpResourceTypeFilter();
    };
    BookingsFilters.prototype.parseBookingTypes = function () {
        if (this.bookingTypes === null || this.bookingTypes === undefined) {
            return null;
        }
        var _loop_1 = function (bt) {
            if (bt.category === "" || bt.category === null) {
                this_1.bookingTypesNoCategory.push({
                    name: bt.name,
                    id: bt.id,
                    byPlace: bt.byPlace,
                });
                return "continue";
            }
            found = this_1.bookingTypesByCategory.find(function (y) { return y.category === bt.category; });
            if (found !== undefined) {
                found.items.push({ name: bt.name, id: bt.id, byPlace: bt.byPlace });
                return "continue";
            }
            this_1.bookingTypesByCategory.push({
                category: bt.category,
                items: [{ name: bt.name, id: bt.id, byPlace: bt.byPlace }],
            });
        };
        var this_1 = this, found;
        for (var _i = 0, _a = this.bookingTypes; _i < _a.length; _i++) {
            var bt = _a[_i];
            _loop_1(bt);
        }
    };
    BookingsFilters.prototype.closeFilters = function () {
        var dom = document.getElementById("filter-menu");
        dom.classList.remove("filter-open");
        dom.classList.add("filter-closed");
    };
    BookingsFilters.prototype.openFilters = function () {
        var dom = document.getElementById("filter-menu");
        dom.classList.remove("filter-closed");
        dom.classList.add("filter-open");
        var domFilterClose = document.getElementById("dom-filter-close");
        domFilterClose === null || domFilterClose === void 0 ? void 0 : domFilterClose.classList.add("active");
    };
    BookingsFilters.prototype.setUpBookingTypeFilter = function () {
        if (this.bookingTypes.length === 1) {
            var domBookingTypes = this.$refs.accordion1;
            domBookingTypes.style.display = "none";
        }
    };
    BookingsFilters.prototype.setUpResourceTypeFilter = function () {
        if (this.resourceTypes === undefined || this.resourceTypes.length <= 1) {
            var dom = this.$refs.accordion3;
            dom.style.display = "none";
        }
    };
    BookingsFilters.prototype.selectBookingType = function (bt) {
        for (var _i = 0, _a = this.bookingTypes; _i < _a.length; _i++) {
            var bt_1 = _a[_i];
            var dom_1 = document.getElementById("checkbox-bookingtype-" + bt_1.id);
            dom_1.classList.remove("selected");
        }
        var dom = document.getElementById("checkbox-bookingtype-" + bt.id);
        dom.classList.add("selected");
        return bt;
    };
    BookingsFilters.prototype.resourceTypeSelected = function (id) {
        for (var _i = 0, _a = this.resourceTypes; _i < _a.length; _i++) {
            var bt = _a[_i];
            var dom = document.getElementById("checkbox-resourcetype-" + bt.id);
            dom.classList.remove("selected");
        }
        if (id !== null) {
            var dom = document.getElementById("checkbox-resourcetype-" + id);
            dom.classList.add("selected");
        }
        return id;
    };
    __decorate([
        Prop()
    ], BookingsFilters.prototype, "resourceTypes", void 0);
    __decorate([
        Prop()
    ], BookingsFilters.prototype, "bookingTypes", void 0);
    __decorate([
        Prop()
    ], BookingsFilters.prototype, "close", void 0);
    __decorate([
        Prop()
    ], BookingsFilters.prototype, "open", void 0);
    __decorate([
        Watch("close")
    ], BookingsFilters.prototype, "closeFilters", null);
    __decorate([
        Watch("open")
    ], BookingsFilters.prototype, "openFilters", null);
    __decorate([
        Emit("bookingTypeSelected")
    ], BookingsFilters.prototype, "selectBookingType", null);
    __decorate([
        Emit("resourceTypeSelected")
    ], BookingsFilters.prototype, "resourceTypeSelected", null);
    BookingsFilters = __decorate([
        Component({
            components: { Accordion: Accordion },
        })
    ], BookingsFilters);
    return BookingsFilters;
}(Vue));
export default BookingsFilters;
