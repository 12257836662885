


























































































































































































































import { Format } from "../../../shared/core/helpers/formatHelper";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { ShopService } from "../../core/services/shopService";
import store from "../../core/store";

@Component
export default class TrendingProducts extends Vue {
  @Prop() mainImage!: string;

  products: any[] = [];
  async mounted() {
    let url = `${Settings.HostName}/api/products/trending-products`;
    let data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Internal server error getting trending products");
      return;
    }
    this.products = data.data;
  }

  formatCurrency(number: number) {
    if (number === undefined) {
      return "";
    }
    return Format.formatCurrency(number.toString());
  }

  getImageProduct(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  addToCart(p: any, e: any) {
    e.preventDefault();
    new ShopService().addProductToCart(p, 1, e.currentTarget);
  }
}
