var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from "../../core/services/crudService";
import { Settings } from "../../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../../core/localStorage";
import { Format } from "../../core/helpers/formatHelper";
import store from "../../core/store";
var MenuSearch = /** @class */ (function (_super) {
    __extends(MenuSearch, _super);
    function MenuSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.settings = {};
        _this.products = null;
        _this.search = "";
        _this.totalCount = 0;
        _this.gridStatus = "";
        return _this;
    }
    MenuSearch.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.settings = store.state.settings;
                return [2 /*return*/];
            });
        });
    };
    MenuSearch.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cid, sid, filters, searchFilter, sort, url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cid = null;
                        sid = LocalStorage.getWithTimeout("cc");
                        if (sid !== undefined && sid !== null) {
                            cid = JSON.parse(sid).id;
                        }
                        filters = {};
                        searchFilter = {};
                        sort = [];
                        sort = [{ colId: "Created", sort: "desc" }];
                        if (this.search !== null && this.search !== "") {
                            searchFilter["Name"] = {
                                condition1: {
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                condition2: {
                                    secondField: "Detail",
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                condition3: {
                                    secondField: "Description",
                                    type: "contains",
                                    filter: this.search,
                                    filterType: "text",
                                },
                                operator: "OR",
                            };
                            filters = Object.assign(filters, searchFilter);
                        }
                        this.gridStatus = JSON.stringify({ filters: filters, sort: sort });
                        this.gridStatus = encodeURIComponent(this.gridStatus);
                        url = cid == null
                            ? Settings.HostName + "/api/shop/list?startRow=0&endRow=1000&gridStatus=" + this.gridStatus
                            : Settings.HostName + "/api/shop/list-by-customer?customerId=" + cid + "&startRow=0&endRow=1000&gridStatus=" + this.gridStatus;
                        return [4 /*yield*/, new CrudService().httpGet(url)];
                    case 1:
                        data = _a.sent();
                        if (data == null || data === undefined || data.ok === false) {
                            console.error("Internal server error getting shop " + cid);
                            return [2 /*return*/];
                        }
                        this.products = data.data;
                        this.totalCount = data.totalCount;
                        window.scrollTo(0, 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    MenuSearch.prototype.getImagePath = function (img) {
        if (img === null || img === undefined || img === "") {
            return this.settings.Uploads + "/" + this.settings.Mainlogo;
        }
        return this.settings.Uploads + "/" + img;
    };
    MenuSearch.prototype.formatPrice = function (price) {
        return Format.formatCurrency(price);
    };
    MenuSearch.prototype.selectSearch = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.search === "") {
                            this.products = null;
                            return [2 /*return*/];
                        }
                        if (!(this.search.length > 3)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MenuSearch.prototype.clearSearch = function () {
        this.search = "";
        this.products = null;
    };
    MenuSearch.prototype.closeForm = function () {
        var dom = this.$refs.toggleButton;
        var domInput = this.$refs.toggleDiv;
        if (dom.classList.contains("active")) {
            dom.classList.remove("active");
            domInput.classList.remove("active");
        }
        this.clearSearch();
    };
    MenuSearch.prototype.getLabel = function (item) {
        return Format.highLightText(item, this.search);
    };
    MenuSearch.prototype.goToShop = function (e, id) {
        e.preventDefault();
        var shopPageSearch = this.search;
        this.closeForm();
        if (id === null || id === undefined || shopPageSearch === "") {
            this.$router.push("/shop");
            this.$router.push({
                name: "shop",
                query: {
                    search: shopPageSearch,
                },
            });
            return;
        }
        this.$router.push("/shop/" + id);
    };
    MenuSearch = __decorate([
        Component({
            components: {},
        })
    ], MenuSearch);
    return MenuSearch;
}(Vue));
export default MenuSearch;
