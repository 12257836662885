var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";
var Popup = /** @class */ (function (_super) {
    __extends(Popup, _super);
    function Popup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Popup.prototype.enableOkBtn = function (value) {
        if (value) {
            this.enable();
        }
    };
    Popup.prototype.watchErrorMsg = function (value) {
        if (value != null) {
            this.enable();
        }
    };
    Popup.prototype.toggleEnableOkButton = function (value) {
        if (value === null) {
            return;
        }
        if (value) {
            this.disable();
        }
        else {
            this.enable();
        }
    };
    Popup.prototype.ok = function () {
        if (this.disableOkButton !== null && this.disableOkButton !== undefined) {
            this.disable();
        }
    };
    Popup.prototype.closed = function () { };
    Popup.prototype.enable = function () {
        var okBtn = this.$refs.okBtn;
        if (okBtn !== undefined && okBtn !== null) {
            okBtn.disabled = false;
        }
    };
    Popup.prototype.disable = function () {
        var okBtn = this.$refs.okBtn;
        okBtn.disabled = true;
    };
    __decorate([
        Prop()
    ], Popup.prototype, "errorMsg", void 0);
    __decorate([
        Prop()
    ], Popup.prototype, "enabled", void 0);
    __decorate([
        Prop({ default: false })
    ], Popup.prototype, "disableOkButton", void 0);
    __decorate([
        Prop({ default: false })
    ], Popup.prototype, "forceFullWidth", void 0);
    __decorate([
        Prop({ default: true })
    ], Popup.prototype, "showOkButton", void 0);
    __decorate([
        Prop({ default: true })
    ], Popup.prototype, "showCancelButton", void 0);
    __decorate([
        Prop({ default: "OK" })
    ], Popup.prototype, "textOkButton", void 0);
    __decorate([
        Prop({ default: "Cancel" })
    ], Popup.prototype, "textCloseButton", void 0);
    __decorate([
        Watch("enabled")
    ], Popup.prototype, "enableOkBtn", null);
    __decorate([
        Watch("errorMsg")
    ], Popup.prototype, "watchErrorMsg", null);
    __decorate([
        Watch("disableOkButton")
    ], Popup.prototype, "toggleEnableOkButton", null);
    __decorate([
        Emit("ok")
    ], Popup.prototype, "ok", null);
    __decorate([
        Emit("closed")
    ], Popup.prototype, "closed", null);
    Popup = __decorate([
        Component({
            components: {},
        })
    ], Popup);
    return Popup;
}(Vue));
export default Popup;
